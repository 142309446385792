import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
import { ShowButton, Show, SimpleShowLayout } from 'react-admin';
import { Filter, SelectInput, TextInput } from 'react-admin';
import { TopToolbar } from 'react-admin';
import { Edit, SimpleForm, EditButton } from 'react-admin';
import JSONPrettyField from '../components/JSONPrettyField';
import SendShippingNotifyEmailButton from "./sendShippingNotifyEmailButton";
import SendShippingInvoiceButton from "./sendShippingInvoiceButton";

const OrdersFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Order Id" source="order_id" alwaysOn />
    <SelectInput source="status" choices={[
      { id: 'ORDER_COMPLETE', name: 'ORDER_COMPLETE' },
      { id: 'ORDER_CANCELLED', name: 'ORDER_CANCELLED' },
      { id: 'ORDER_FULLREFUND', name: 'ORDER_FULLREFUND' }
    ]} alwaysOn />
  </Filter>
);

const OrdersList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }} filters={<OrdersFilter />}>
    <Datagrid>
      <TextField source="order_id" />
      <TextField source="status" />
      <ReferenceField label="Buyer" source="user_id" reference="users" link="show" allowEmpty>
        <TextField source="username" />
      </ReferenceField>
      <TextField label="Buy at" source="inserted_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const OrdersShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <SendShippingInvoiceButton record={data} />
    {/* <SendShippingNotifyEmailButton record={data} /> */}
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const OrdersShow = (props) => (
  <Show actions={<OrdersShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="order_id" />
      <ReferenceField label="Buyer" source="user_id" reference="users" link="show" allowEmpty>
        <TextField source="username" />
      </ReferenceField>
      <TextField source="status" />
      <TextField source="stripe_amount" />
      <TextField source="stripe_currency" />
      <JSONPrettyField source="shipping_details" />
      <JSONPrettyField source="merchpunk_items" />
      <JSONPrettyField source="stripe_metadata" />
      <TextField source="inserted_at" />
      <TextField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

const OrdersEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextField source="order_id" />
      <ReferenceField label="Buyer" source="user_id" reference="users" link="show" allowEmpty>
        <TextField source="username" />
      </ReferenceField>
      <TextField source="stripe_amount" />
      <TextField source="stripe_currency" />
      <SelectInput source="status" choices={[
        { id: 'ORDER_COMPLETE', name: 'ORDER_COMPLETE' },
        { id: 'ORDER_CANCELLED', name: 'ORDER_CANCELLED' },
        { id: 'ORDER_FULLREFUND', name: 'ORDER_FULLREFUND' }
      ]} fullWidth />
      <JSONPrettyField source="shipping_details" />
      <JSONPrettyField source="merchpunk_items" />
      <JSONPrettyField source="stripe_metadata" />
      <TextField label="Buy at" source="inserted_at" />
    </SimpleForm>
  </Edit>
);


export default {
  list: OrdersList,
  show: OrdersShow,
  edit: OrdersEdit
};