import React from 'react';
import { Filter, List, Datagrid, ReferenceField, TextField, SelectInput } from 'react-admin';
import { EditButton, ShowButton, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Show, SimpleShowLayout, NumberInput, TextInput, RadioButtonGroupInput, ArrayInput } from 'react-admin';
import { Create, Edit, required, TabbedForm, FormTab, FormDataConsumer, SimpleFormIterator } from 'react-admin';
import JSONPrettyField from '../components/JSONPrettyField';
import JSONPrettyInput from '../components/JSONPrettyInput';
import ImageUrlTextInput from '../components/ImageUrlTextInput';
import FileUrlTextInput from '../components/FileUrlTextInput';

const ArtworkMediaFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Filter by artwork id" source="artwork_id" alwaysOn />
    <SelectInput source="media.type" choices={[
      { id: 'image', name: 'Image' },
      { id: 'bundle', name: 'Bundle' },
      { id: 'video', name: 'Video' }
    ]} alwaysOn />
  </Filter>
);

const ArtworkMediaList = (props) => (
  <List {...props} filters={<ArtworkMediaFilter />}>
    <Datagrid>
      <TextField label="Artwork Id" source="artwork_id" />
      <ReferenceField label="Artwork Slug" source="artwork_id" reference="artworks" link="show" allowEmpty>
        <TextField source="slug" />
      </ReferenceField>
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const ArtworkMediaShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Artwork" source="artwork_id" reference="artworks" link="show" allowEmpty>
        <TextField source="slug" />
      </ReferenceField>
      <JSONPrettyField source="media" />
    </SimpleShowLayout>
  </Show>
);

const ArtworkMediaEdit = (props) => (
  <Edit {...props}>
    <TabbedForm submitOnEnter={false}>
      <FormTab label="identity">
        <TextInput source="id" disabled />
        <ReferenceInput label="Artwork" source="artwork_id" filterToQuery={searchText => ({ slug: searchText })} reference="artworks" allowEmpty fullWidth>
          <AutocompleteInput optionText={choice => choice ? choice.slug : ""} options={{ fullWidth: true }} />
        </ReferenceInput>
        <RadioButtonGroupInput label="Type" source="media.type" row={false} defaultValue="image" choices={[
          { id: 'image', name: 'Image' },
          { id: 'bundle', name: 'Bundle' },
          { id: 'video', name: 'Video' }
        ]} />
      </FormTab>
      <FormTab label="Media Content">
        <FormDataConsumer>
          {({ formData, ...rest }) => {

            if (!formData.media || formData.media.type === "image") {
              return <ImageUrlTextInput source="media.url" {...rest} fullWidth />
            }

            if (formData.media.type === "video") {
              return (
                <div>
                  <TextInput label="Trailer Id" source="media.trailer" {...rest} />
                  <JSONPrettyInput source="media.episodes" {...rest} fullWidth />
                </div>
              )
            }

            if (formData.media.type === "bundle") {
              const { data } = props;
              return (
                <ArrayInput source="media.files">
                  <SimpleFormIterator>
                    <TextInput label="Name" source="name" fullWidth />
                    <TextInput label="Name Jp" source="name_jp" fullWidth />
                    <FileUrlTextInput label="File Url" source="url" record={data} fullWidth />
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }
          }}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Edit>
);

const ArtworkMediaCreate = (props) => (
  <Create title="Create Artwork Media" {...props}>
    <TabbedForm submitOnEnter={false}>
      <FormTab label="identity">
        <ReferenceInput label="Artwork" source="artwork_id" filterToQuery={searchText => ({ slug: searchText })} reference="artworks" allowEmpty validate={required()} fullWidth>
          <AutocompleteInput optionText={choice => choice ? choice.slug : ""} options={{ fullWidth: true }} />
        </ReferenceInput>
        <RadioButtonGroupInput label="Type" source="media.type" row={false} defaultValue="image" choices={[
          { id: 'image', name: 'Image' },
          { id: 'bundle', name: 'Bundle' },
          { id: 'video', name: 'Video' },
        ]} />
      </FormTab>
      <FormTab label="Media Content">
        <FormDataConsumer>
          {({ formData, ...rest }) => {

            if (!formData.media || formData.media.type === "image") {
              return <ImageUrlTextInput source="media.url" {...rest} fullWidth />
            }

            if (formData.media.type === "video") {
              return (
                <div>
                  <TextInput label="Trailer Id" source="media.trailer" {...rest} />
                  <JSONPrettyInput source="media.episodes" {...rest} fullWidth />
                </div>
              )
            }

            if (formData.media.type === "bundle") {
              return (
                <ArrayInput source="media.files">
                  <SimpleFormIterator>
                    <TextInput label="Name" source="name" fullWidth />
                    <TextInput label="Name Jp" source="name_jp" fullWidth />
                    <FileUrlTextInput label="File Url" source="url" fullWidth />
                  </SimpleFormIterator>
                </ArrayInput>
              )
            }
          }}
        </FormDataConsumer>
      </FormTab>
    </TabbedForm>
  </Create>
);

export default {
  list: ArtworkMediaList,
  create: ArtworkMediaCreate,
  edit: ArtworkMediaEdit,
  show: ArtworkMediaShow
};