import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Layout } from './layout';
import { createBrowserHistory } from 'history'
import polyglotI18nProvider from 'ra-i18n-polyglot';
import englishMessages from 'ra-language-english';
import postgrestClient from './lib/postgrestProvider';
import customRoutes from './customRoutes';
import authProvider from './authProvider';
import auth0Client from "./lib/auth";
import Dashboard from './Dashboard';
import users from './users'
import contracts from './contracts'
import tokens from './tokens'
import artworks from './artworks'
import artworksBadges from './artworks_badges'
import badges from './badges'
import transactions from './transactions'
import artworksMedia from './artworks_media'
import videoIds from './video_ids'
import artworkExtras from './artwork_extras'
import merchpunkOrderItems from './merchpunk_order_items';
import merchpunkOrders from './merchpunk_orders';
import merchpunkStoreItems from './merchpunk_store_items';
import merchpunkShippingInvoices from './merchpunk_shipping_invoices';

import './App.css';

const history = createBrowserHistory();

function validateAuthToken() {
  const token = localStorage.getItem('token');
  if (token) {
    const promise = auth0Client.silentAuth();
    promise.then(
      () => {
        // token valid, do nothing
        //console.log("token valid");
      },
      err => {
        // console.log("INVALID TOKEN");
        localStorage.removeItem('token');
        localStorage.removeItem('role');
        auth0Client.signOut();
      }
    );
  }
}

const App = () => {
  const apiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}`
  const i18nProvider = polyglotI18nProvider(() =>
    englishMessages,
    'en',
    {
      allowMissing: true
    }
  );
  validateAuthToken();

  return (
    <Admin
      locale="en"
      dashboard={Dashboard}
      dataProvider={postgrestClient(apiUrl)}
      customRoutes={customRoutes}
      authProvider={authProvider}
      i18nProvider={i18nProvider}
      history={history}
      layout={Layout}
    >
      {permissions => [
        permissions === 'admin' ? <Resource name="users" {...users} /> : <div />,
        permissions === 'admin' ? <Resource name="artworks" {...artworks} /> : <div />,
        permissions === 'admin' ? <Resource name="artworks_media" {...artworksMedia} /> : <div />,
        permissions === 'admin' ? <Resource name="artworks_extras" {...artworkExtras} /> : <div />,
        permissions === 'admin' ? <Resource name="video_ids" {...videoIds} /> : <div />,
        permissions === 'admin' ? <Resource name="badges" {...badges} /> : <div />,
        permissions === 'admin' ? <Resource name="artworks_badges" {...artworksBadges} /> : <div />,
        permissions === 'admin' ? <Resource name="contracts" {...contracts} /> : <div />,
        permissions === 'admin' ? <Resource name="tokens" {...tokens} /> : <div />,
        permissions === 'admin' ? <Resource name="transactions" {...transactions} /> : <div />,
        permissions === 'admin' ? <Resource name="merchpunk_order_items" {...merchpunkOrderItems} /> : <div />,
        permissions === 'admin' ? <Resource name="merchpunk_orders" {...merchpunkOrders} /> : <div />,
        permissions === 'admin' ? <Resource name="merchpunk_store_items" {...merchpunkStoreItems} /> : <div />,
        permissions === 'admin' ? <Resource name="merchpunk_shipping_invoices" {...merchpunkShippingInvoices} /> : <div />
      ]}
    </Admin>
  )
};

export default App;
