import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { EditButton, TextInput, ReferenceField } from 'react-admin';
import { Filter, SimpleShowLayout, required, SimpleForm, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Create, Edit, Show } from 'react-admin';
import FileUrlTextInput from '../components/FileUrlTextInput';

const VideoIdsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Filter by artwork id" source="artwork_id" alwaysOn />
  </Filter>
);
const VideoIdsList = (props) => (
  <List {...props} filters={<VideoIdsFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Artwork" source="artwork_id" reference="artworks" link="show" allowEmpty>
        <TextField source="slug" />
      </ReferenceField>
      <TextField source="trailer_id" />
      <TextField source="movie_id" />
      <EditButton />
    </Datagrid>
  </List>
);
const VideoIdsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Artwork" source="artwork_id" reference="artworks" link="show" allowEmpty>
        <TextField source="slug" />
      </ReferenceField>
      <TextField source="trailer_id" />
      <TextField source="movie_id" />
    </SimpleShowLayout>
  </Show>
);

const VideoIdsEdit = (props) => (
  <Edit title="Edit Artwork Video" {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <ReferenceInput label="Artwork" source="artwork_id" filterToQuery={searchText => ({ slug: searchText })} reference="artworks" allowEmpty fullWidth>
        <AutocompleteInput optionText={choice => choice.slug} options={{ fullWidth: true }} />
      </ReferenceInput>
      <TextInput source="trailer_id" validate={required()} fullWidth />
      {/* <TextInput source="movie_id" validate={required()} fullWidth /> */}
      <FileUrlTextInput label="Movie File" source="movie_id" fullWidth />
    </SimpleForm>
  </Edit>
);

const VideoIdsCreate = (props) => (
  <Create title="Create Artwork Video" {...props}>
    <SimpleForm>
      <ReferenceInput label="Artwork" source="artwork_id" filterToQuery={searchText => ({ slug: searchText })} reference="artworks" allowEmpty fullWidth>
        <AutocompleteInput optionText={choice => choice.slug} options={{ fullWidth: true }} />
      </ReferenceInput>
      <TextInput source="trailer_id" validate={required()} fullWidth />
      {/* <TextInput source="movie_id" validate={required()} fullWidth /> */}
      <FileUrlTextInput label="Movie File" source="movie_id" fullWidth />
    </SimpleForm>
  </Create>
);

export default {
  list: VideoIdsList,
  create: VideoIdsCreate,
  edit: VideoIdsEdit,
  show: VideoIdsShow
};