import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { EditButton, SimpleForm, TextInput, ReferenceInput, AutocompleteInput, DateTimeInput } from 'react-admin';
import { Show, SimpleShowLayout, NumberInput } from 'react-admin';
import { Create, Edit, required } from 'react-admin';
import CurrentDateTimeInput from '../components/CurrentDateTimeInput'
import { parse } from 'query-string';

const ArtworkBadgesList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="artwork_id" />
      <TextField source="badge_id" />
      <EditButton />
    </Datagrid>
  </List>
);

const ArtworkBadgesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="artwork_id" />
      <TextField source="badge_id" />
    </SimpleShowLayout>
  </Show>
);

const ArtworkBadgesEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <ReferenceInput label="Artwork" source="artwork_id" filterToQuery={searchText => ({ slug: searchText })} reference="artworks">
        <AutocompleteInput optionText={choice => choice ? choice.slug : ""} />
      </ReferenceInput>
      <ReferenceInput label="Badge" source="badge_id" filterToQuery={searchText => ({ key: searchText })} reference="badges">
        <AutocompleteInput optionText={choice => choice ? choice.key : ""} />
      </ReferenceInput>
      <CurrentDateTimeInput source="updated_at" />
    </SimpleForm>
  </Edit>
);

const ArtworkBadgesCreate = (props) => {
  // Read the post_id from the location which is injected by React Router and passed to our component by react-admin automatically
  const { artwork_id: artwork_id_string } = parse(props.location.search);
  // ra-data-fakerest uses integers as identifiers, we need to parse the querystring
  // We also must ensure we can still create a new comment without having a post_id
  // from the url by returning an empty string if post_id isn't specified
  const artwork_id = artwork_id_string ? parseInt(artwork_id_string, 10) : '';
  const redirect = artwork_id ? `/artworks/${artwork_id}/badges` : false;

  return (
    <Create title="Link Artwork Badge" {...props}>
      <SimpleForm
        initialValues={{ artwork_id }}
        redirect={redirect}>
        <NumberInput label="Artwork Id" source="artwork_id" validate={required()} />
        <ReferenceInput label="Badge" source="badge_id" filterToQuery={searchText => ({ key: searchText })} reference="badges" validate={required()}>
          <AutocompleteInput optionText={choice => choice.key} allowEmpty />
        </ReferenceInput>
        <DateTimeInput source="inserted_at" defaultValue={new Date()} />
        <DateTimeInput source="updated_at" defaultValue={new Date()} />
      </SimpleForm>
    </Create>
  )
};

export default {
  list: ArtworkBadgesList,
  create: ArtworkBadgesCreate,
  edit: ArtworkBadgesEdit,
  show: ArtworkBadgesShow
};