import React from 'react';
import JSONPretty from 'react-json-pretty';

const JSONPrettyField = (props) => {
  const { record, source } = props;
  const data = record[source];

  return (
    <JSONPretty data={data} themeClassName="custom-json-pretty" onError={e => console.error("json pretty error", e)}></JSONPretty>
  )
};

JSONPrettyField.defaultProps = {
  addLabel: true,
};

export default JSONPrettyField;