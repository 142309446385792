import React from 'react';
import { Filter, List, Datagrid, TextField, ReferenceField } from 'react-admin';
import { EditButton, SimpleForm, TextInput, DateTimeInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Show, SimpleShowLayout, ShowButton } from 'react-admin';
import { Create, Edit, RadioButtonGroupInput, NumberInput } from 'react-admin';
import { TopToolbar } from 'react-admin';
import CurrentDateTimeInput from '../components/CurrentDateTimeInput';
import TransferTokenButton from './TransferTokenButton';

const TokenFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Filter by artwork id" source="artwork_id" alwaysOn />
    <NumberInput label="Filter by owner id" source="owner_id" alwaysOn />
  </Filter>
);
const TokenList = (props) => (
  <List {...props} sort={{ field: 'inserted_at', order: 'DESC' }} filters={<TokenFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Owner" source="owner_id" reference="users" link="show">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField label="Artwork" source="artwork_id" reference="artworks" link="show" allowEmpty>
        <TextField source="slug" />
      </ReferenceField>
      <TextField source="merchpunk_item_id" />
      <TextField source="status" />
      <TextField source="sale_status" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const TokenShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <TransferTokenButton record={data} resource={resource} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);
const TokenShow = (props) => (
  <Show actions={<TokenShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Owner" source="owner_id" reference="users" link="show">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="artwork_id" />
      <TextField source="merchpunk_item_id" />
      <TextField source="status" />
      <TextField source="sale_status" />
    </SimpleShowLayout>
  </Show>
);

const TokenEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <ReferenceInput label="Owner" source="owner_id" filterToQuery={searchText => ({ username: searchText })} reference="users" allowEmpty>
        <AutocompleteInput optionText={choice => `${choice.id}: ${choice.username}`} />
      </ReferenceInput>
      <TextInput source="artwork_id" />
      <TextInput source="merchpunk_item_id" />
      <RadioButtonGroupInput source="status" choices={[
        { id: 'CLAIMABLE', name: 'Claimable' },
        { id: 'PENDING', name: 'Pending' },
        { id: 'ACTIVE', name: 'Active' },
        { id: 'DISABLED', name: 'Disabled' },
      ]} />
      <RadioButtonGroupInput source="sale_status" choices={[
        { id: 'NOT_FOR_SALE', name: 'Not for Sale' },
        { id: 'USER', name: 'User' },
        { id: 'SNATCH', name: 'Snatch (do not use)' },
        { id: 'AUCTION', name: 'Auction (do not use)' },
      ]} />
      <NumberInput source="current_price" />
      <NumberInput source="current_price_usd" />
      <NumberInput source="current_price_jpy" />
      <TextInput source="inserted_at" disabled />
      <CurrentDateTimeInput source="updated_at" />
    </SimpleForm>
  </Edit>
);

const TokenCreate = (props) => (
  <Create title="Create a Token" {...props}>
    <SimpleForm>
      <ReferenceInput label="Owner" source="owner_id" filterToQuery={searchText => ({ username: searchText })} reference="users" allowEmpty>
        <AutocompleteInput optionText={choice => `${choice.id}: ${choice.username}`} />
      </ReferenceInput>
      <TextInput source="artwork_id" />
      <TextInput source="merchpunk_item_id" />
      <RadioButtonGroupInput source="status" defaultValue="PENDING" choices={[
        { id: 'PENDING', name: 'Pending' },
        { id: 'ACTIVE', name: 'Active' },
        { id: 'DISABLED', name: 'Disabled' },
      ]} />
      <RadioButtonGroupInput source="sale_status" defaultValue="SNATCH" choices={[
        { id: 'NOT_FOR_SALE', name: 'Not for Sale' },
        { id: 'USER', name: 'User' },
        { id: 'SNATCH', name: 'Snatch (do not use)' },
        { id: 'AUCTION', name: 'Auction (do not use)' },
      ]} />
      <NumberInput source="current_price" />
      <NumberInput source="current_price_usd" />
      <NumberInput source="current_price_jpy" />
      <DateTimeInput source="inserted_at" defaultValue={new Date()} />
      <DateTimeInput source="updated_at" defaultValue={new Date()} />
    </SimpleForm>
  </Create>
);

export default {
  list: TokenList,
  create: TokenCreate,
  edit: TokenEdit,
  show: TokenShow
};