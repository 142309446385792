import auth0 from "auth0-js";

class Auth {
  constructor() {
    const redirectUrl = `${window.top.location.origin}/auth`;
    // console.log(redirectUrl);
    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      audience: `https://${process.env.REACT_APP_AUTH0_DOMAIN_RESOLVED}/userinfo`,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: redirectUrl,
      responseType: 'token id_token',
    });

    this.login = this.login.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.silentAuth = this.silentAuth.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  login(username, password) {
    return new Promise((resolve, reject) => {
      this.auth0.login({
        realm: process.env.REACT_APP_AUTH0_CONNECTION,
        username: username,
        password: password
      }, function(err) {
          if (err) {
            console.log(err);
            localStorage.removeItem('role');
            localStorage.removeItem('token');
            reject('Invalid username and password');
          } 
          resolve();
        }
      );
    });
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(new Error("Invalid Authentication Token"));
        }
        resolve(authResult.idToken);
      });
    });
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(new Error("Invalid Authentication Token"));
        }
        resolve();
      });
    });
  }

  signOut() {
    const logoutCallback = `${window.top.location.origin}/login`;
    this.auth0.logout({
      returnTo: logoutCallback,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;