import React, { useState, Fragment } from 'react';
import nanoid from "nanoid";
import request from "superagent";
import { Form, useForm } from 'react-final-form';
import {
  Button,
  SaveButton,
  ImageInput,
  ImageField,
  useNotify
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const ImageUploadButton = ({ source }) => {
  const notify = useNotify();
  const recordForm = useForm();
  const [showDialog, setShowDialog] = useState(false);

  const onShowDialog = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const appendUniqueName = (file) => {
    const ext = file.name.split(".").pop();
    const randomId = nanoid();
    file.uniqueName = `${randomId}.${ext}`;
    return file;
  }

  const onUpload = (values) => {
    let { file } = values;
    // console.log(values);

    file = appendUniqueName(file.rawFile);
    const imageName = file.uniqueName;
    const getSignedUrl = `${process.env.REACT_APP_API_ENDPOINT}/image/sign/${imageName}`;
    // console.log(file);
    // console.log(getSignedUrl);
    request.get(getSignedUrl).end((err, res) => {
      if (err) {
        console.log("err", err);
        notify(err.message, 'error');
        return;
      }

      // console.log(res);
      const signedUrl = res.body.url;
      // console.log('signedUrl', signedUrl);
      const mime = res.body.mime;
      request.put(signedUrl)
        .set("Content-Type", mime)
        .send(file)
        .end((err, res) => {
          if (err) {
            console.log("err", err);
            notify(err.message, 'error');
            return;
          }

          const imageUrl = `https://img-cdn.blockpunk.net/${imageName}`;
          console.log('image url', imageUrl);
          console.log('source', source);
          recordForm.change(source, imageUrl);
          setShowDialog(false);
        });
    });
  };

  return (
    <Fragment>
      <Button onClick={onShowDialog} label="Upload Image">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={onCloseDialog}
      >
        <DialogTitle>Upload Image</DialogTitle>
        <Form
          onSubmit={onUpload}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <ImageInput source="file" label="" accept="image/*" placeholder={<p>Drop your image here</p>}>
                    <ImageField source="src" title="Preview" />
                  </ImageInput>
                </DialogContent>
                <DialogActions>
                  <SaveButton
                    label="Upload"
                    saving={submitting}
                    handleSubmitWithRedirect={event => { handleSubmit(event) }}
                  />
                  <Button label="Cancel" onClick={onCloseDialog}>
                    <IconCancel />
                  </Button>
                </DialogActions>
              </form>
            )
          }} />
      </Dialog>
    </Fragment>
  )
}

export default ImageUploadButton;