import {
  AUTH_GET_PERMISSIONS,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK
} from 'react-admin';
import auth0Client from "./lib/auth";

// Authenticatd by default
export default async (type, params) => {
  //console.log("type", type);
  //console.log("params", params);
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const promise = await auth0Client.login(username, password);
    //console.log(promise);
    promise.then(
      () => {
        // waiting for redirect from auth0
      },
      err => {
        // Promise is rejected
        return Promise.reject(err);
      }
    );
  }
  if (type === AUTH_LOGOUT) {
    auth0Client.signOut();
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    return Promise.resolve();
  }
  if (type === AUTH_ERROR) {
    const status = params.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem('token');
      localStorage.removeItem('role');
      return Promise.reject();
    }
    return Promise.resolve();
  }
  if (type === AUTH_CHECK) {
    const token = localStorage.getItem('token');
    return token ? Promise.resolve() : Promise.reject();
  }
  if (type === AUTH_GET_PERMISSIONS) {
    const role = localStorage.getItem('role');
    //console.log('authProvider role', role);
    return role ? Promise.resolve(role) : Promise.resolve('guest');
  }

  return Promise.reject('Unknown method');
};