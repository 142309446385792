import React, { Component } from 'react';
import { TextInput } from 'react-admin';
import FileUploadButton from './FileUploadButton';

class FileUrlTextInput extends Component {

  render() {
    return (
      <div>
        <TextInput {...this.props} />
        <FileUploadButton {...this.props} />
      </div>
    )
  }
}

export default FileUrlTextInput;