import React from 'react';
import { TextInput } from 'react-admin';

const dateFormatter = v => {
  const date = new Date();
  // timestamp without time zone
  return date.toISOString();
};

const dateParser = v => {
  return new Date();
};

/*
We want to support current date time for record like: updated date.
User doesnt need to input. the value is gotten from system clock.
*/
export default (props) => {
  //console.log(props);
  return (
    <TextInput {...props} format={dateFormatter} parse={dateParser} />
  )
};