import React from 'react';

const CertificateField = (props) => {
  const { record } = props;
  const { token_id: tokenId } = record;
  if (!tokenId) {
    return (
      <p>[Token Id is EMPTY]</p>
    )
  }

  const url = `${process.env.REACT_APP_WEBSITE_DOMAIN}/en/certificate/${tokenId}`;
  const urlJp = `${process.env.REACT_APP_WEBSITE_DOMAIN}/jp/certificate/${tokenId}`;

  return (
    <div>
      <p><a href={url}>{url}</a></p>
      <p><a href={urlJp}>{urlJp}</a></p>
    </div>
  )
};

CertificateField.defaultProps = {
  addLabel: true,
  label: "Certificate URL"
};

export default CertificateField;