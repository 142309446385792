import React, { useState, Fragment } from 'react';
import {
  Button,
  SaveButton,
  NumberInput,
  RadioButtonGroupInput,
  required,
  useRefresh,
  useNotify
} from 'react-admin';
import { Form } from 'react-final-form'
import { retrieveUser } from "../lib/request";
import { retrieveStripeCustomer, createStripeCustomer, createStripeInvoice } from "../lib/stripeRequest";
import request from "superagent";
import EmailIcon from '@material-ui/icons/Email';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const MerchPunkShippingInvoiceButton = ({ record }) => {
  const { metadata, status } = record || {};
  let metadataJson = metadata;
  if (metadata && typeof (metadata) === "string") metadataJson = JSON.parse(metadata);

  const { merch_id: merchId, stripe_shipping_invoice_id: exsitedShippingInvoiceId, stripe_currency: originPaymentCurrency } = metadataJson || {};
  const [showDialog, setShowDialog] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const isDisabled = !merchId || status !== "MERCHPUNK_CHECKOUT_COMPLETED";
  const sendInvoiceDisabled = exsitedShippingInvoiceId;
  const defaultCurrency = originPaymentCurrency ? originPaymentCurrency.toLowerCase() : "";

  const onOpenDialog = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onSendInvoice = async values => {
    const { id: txId, to_id: buyerId } = record;
    const orderId = "B" + txId.toString().padStart(10, '0');

    const buyer = await retrieveUser(buyerId);
    if (!buyer) {
      notify('Error: retrieve buyer failed', 'error');
      return;
    }

    let stripeCustomerId = undefined;
    const buyerStripeCustomer = await retrieveStripeCustomer(buyerId);
    if (!buyerStripeCustomer) {
      // create stripe customer for this buyer
      const stripeCustomerResult = await createStripeCustomer(buyer);
      console.log("CREATE stripeCustomer", stripeCustomerResult);
      stripeCustomerId = stripeCustomerResult.data.id;
    } else {
      stripeCustomerId = buyerStripeCustomer.customer_id;
    }

    console.log("stripeCustomerId", stripeCustomerId);
    if (!stripeCustomerId) {
      notify('Error: retrieve buyer stripe customer id failed', 'error');
      return;
    }

    const { currency, shippingInJapanAmount, shippingOutsideJapanAmount } = values;
    const createInvoiceResult = await createStripeInvoice(stripeCustomerId, orderId, currency, shippingInJapanAmount, shippingOutsideJapanAmount);
    if (!createInvoiceResult) {
      notify('Error: send shipping invoice failed', 'error');
    }
    console.log("createInvoiceResult", createInvoiceResult);
    const { id: invoiceId } = createInvoiceResult.data;
    if (!invoiceId) {
      notify('Error: shipping invoice Id not exists', 'error');
    } else {
      const updatedMetadata = { ...metadataJson, stripe_shipping_invoice_id: invoiceId };
      const updatedRecord = { ...record, metadata: updatedMetadata };
      const transactionApiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/transactions`

      request.patch(`${transactionApiUrl}?id=eq.${txId}`)
        .set("Content-Type", "application/json")
        .set("apikey", process.env.REACT_APP_API_KEY)
        .send(updatedRecord)
        .end(async (err, res) => {
          if (err) {
            console.log(err);
            notify('Error: update transaction failed', 'error');
            return;
          }

          notify('Send shipping invoice successfully');
          setShowDialog(false);
          refresh();
        });
    }
  }

  return (
    <Fragment>
      <Button onClick={onOpenDialog} disabled={isDisabled} label="Send MerchPunk Shipping Invoice">
        <EmailIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={onCloseDialog}
        aria-label="Send MerchPunk Shipping Invoice"
      >
        <DialogTitle>Send MerchPunk Shipping Invoice</DialogTitle>
        <Form
          onSubmit={onSendInvoice}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <RadioButtonGroupInput source="currency" validate={required()} defaultValue={defaultCurrency} choices={[
                    { id: 'usd', name: 'USD' },
                    { id: 'jpy', name: 'JPY' }
                  ]} />
                  <NumberInput source="shippingInJapanAmount" defaultValue="" validate={required()} fullWidth />
                  <NumberInput source="shippingOutsideJapanAmount" defaultValue="" validate={required()} fullWidth />
                  {exsitedShippingInvoiceId && <p className="warning">{`Existed Shipping Invoice Id: ${exsitedShippingInvoiceId}`}</p>}
                </DialogContent>
                <DialogActions>
                  <SaveButton
                    label="Send Invoice"
                    saving={submitting}
                    handleSubmitWithRedirect={event => { handleSubmit(event) }}
                    disabled={sendInvoiceDisabled} />
                  <Button label="Cancel" onClick={onCloseDialog}>
                    <IconCancel />
                  </Button>
                </DialogActions>
              </form>
            )
          }} />
      </Dialog>
    </Fragment>
  )
}

export default MerchPunkShippingInvoiceButton;