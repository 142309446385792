import React from 'react';
import { List, Datagrid, TextField, ReferenceField, ReferenceFieldController } from 'react-admin';
import { ShowButton, ExportButton, Show, SimpleShowLayout } from 'react-admin';
import { Filter, NumberInput, SelectInput } from 'react-admin';
import { Edit, SimpleForm, EditButton } from 'react-admin';
import { TopToolbar } from 'react-admin';
import CurrencyField from '../components/CurrencyField';
import CertificateField from '../components/CertificateField';
import JSONPrettyField from '../components/JSONPrettyField';
import ShippingNotifButton from './shippingNotifButton';
import MerchPunkShippingInvoiceButton from './merchpunkShippingInvoiceButton';
import PrepareArtprintForShippingButton from './prepareArtprintForShippingButton';

const TransactionsFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Filter by token id" source="token_id" alwaysOn />
    <NumberInput label="Filter by buyer id" source="to_id" alwaysOn />
    <SelectInput source="status" alwaysOn choices={[
      { id: 'DAI_TRANSFER_COMPLETED', name: 'DAI_TRANSFER_COMPLETED' },
      { id: 'FULL_REFUNDED', name: 'FULL_REFUNDED' },
      { id: 'MERCHPUNK_CHECKOUT_COMPLETED', name: 'MERCHPUNK_CHECKOUT_COMPLETED' },
      { id: 'MERCHPUNK_PAYMENT_COMPLETED', name: 'MERCHPUNK_PAYMENT_COMPLETED' },
      { id: 'NO_DAI_TRANSFER', name: 'NO_DAI_TRANSFER' },
      { id: 'PREORDED_PAYMENT_COMPLETED', name: 'PREORDED_PAYMENT_COMPLETED' },
      { id: 'TRANSFER_INITIATED', name: 'TRANSFER_INITIATED' },
    ]} />
  </Filter>
);

const TransactionsListActions = ({ currentSort, displayedFilters, exporter, resource, total, filters, filterValues, showFilter }) => (
  <TopToolbar>
    {filters && React.cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}
    <PrepareArtprintForShippingButton />
    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </TopToolbar>
);

const TransactionsList = (props) => (
  <List actions={<TransactionsListActions />} {...props} sort={{ field: 'id', order: 'DESC' }} filters={<TransactionsFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceFieldController label="Cryptoart" source="token_id" reference="tokens" allowEmpty>
        {({ referenceRecord }) => (
          <ReferenceField basePath="/artworks" source="artwork_id" reference="artworks" link="show" record={referenceRecord || {}} allowEmpty>
            <TextField source="slug" />
          </ReferenceField>
        )}
      </ReferenceFieldController>
      <ReferenceFieldController label="Merchpunk Item" source="token_id" reference="tokens" allowEmpty>
        {({ referenceRecord }) => (
          <TextField source="merchpunk_item_id" record={referenceRecord || {}} allowEmpty />
        )}
      </ReferenceFieldController>
      <ReferenceField label="Buyer" source="to_id" reference="users" link="show" allowEmpty>
        <TextField source="username" />
      </ReferenceField>
      <CurrencyField source="price" />
      <TextField source="status" />
      <TextField source="payment_source" />
      <TextField label="Buy at" source="inserted_at" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const TransactionsShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <MerchPunkShippingInvoiceButton record={data} />
    {/* <ShippingNotifButton record={data} /> */}
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

const TransactionsShow = (props) => (
  <Show actions={<TransactionsShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceFieldController label="Cryptoart" source="token_id" reference="tokens" addLabel allowEmpty>
        {({ referenceRecord }) => (
          <ReferenceField basePath="/artworks" source="artwork_id" reference="artworks" link="show" record={referenceRecord || {}} allowEmpty>
            <TextField source="slug" />
          </ReferenceField>
        )}
      </ReferenceFieldController>
      <ReferenceField label="Seller" source="from_id" reference="users" allowEmpty link="show">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField label="Buyer" source="to_id" reference="users" allowEmpty link="show">
        <TextField source="username" />
      </ReferenceField>
      <CertificateField source="token_id" />
      <CurrencyField source="price" />
      <JSONPrettyField source="metadata" />
      <TextField source="status" />
      <TextField source="payment_source" />
      <TextField source="eth_transaction_id" />
      <TextField source="from_address" />
      <TextField source="to_address" />
      <TextField source="inserted_at" />
      <TextField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

const TransactionsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <ReferenceFieldController label="Cryptoart" source="token_id" reference="tokens" allowEmpty>
        {({ referenceRecord }) => (
          <ReferenceField basePath="/artworks" source="artwork_id" reference="artworks" link="show" record={referenceRecord || {}} allowEmpty>
            <TextField source="slug" />
          </ReferenceField>
        )}
      </ReferenceFieldController>
      <ReferenceField label="Buyer" source="to_id" reference="users" link="show" allowEmpty>
        <TextField source="username" />
      </ReferenceField>
      <CurrencyField source="price" />
      <SelectInput source="status" choices={[
        { id: 'DAI_TRANSFER_COMPLETED', name: 'DAI_TRANSFER_COMPLETED' },
        { id: 'FULL_REFUNDED', name: 'FULL_REFUNDED' },
        { id: 'MERCHPUNK_CHECKOUT_COMPLETED', name: 'MERCHPUNK_CHECKOUT_COMPLETED' },
        { id: 'MERCHPUNK_PAYMENT_COMPLETED', name: 'MERCHPUNK_PAYMENT_COMPLETED' },
        { id: 'NO_DAI_TRANSFER', name: 'NO_DAI_TRANSFER' },
        { id: 'PREORDED_PAYMENT_COMPLETED', name: 'PREORDED_PAYMENT_COMPLETED' },
        { id: 'TRANSFER_INITIATED', name: 'TRANSFER_INITIATED' },
        { id: 'SHOPIFY_PURCHASE', name: 'SHOPIFY_PURCHASE' },
      ]} fullWidth />
      <TextField source="payment_source" />
      <TextField label="Buy at" source="inserted_at" />
    </SimpleForm>
  </Edit>
);

export default {
  list: TransactionsList,
  show: TransactionsShow,
  edit: TransactionsEdit,
};