import React from 'react';
import { TextField, UrlField, ImageField } from 'react-admin';
import { List, Datagrid, TextInput } from 'react-admin';
import { ShowButton, Show, SimpleShowLayout } from 'react-admin';
import { Edit, SimpleForm, EditButton } from 'react-admin';
import { Filter, NumberInput, SelectInput } from 'react-admin';
import JSONPrettyField from '../components/JSONPrettyField';
import JSONPrettyInput from '../components/JSONPrettyInput';

const StoreItemsFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Filter by id" source="id" default={0} alwaysOn />
  </Filter>
);

const StoreItemsList = (props) => (
  <List {...props} filterDefaultValues={{ id: 1 }} filters={<StoreItemsFilter />}>
    <Datagrid>
      <TextField source="id" />
      <UrlField source="url" />
      <TextField source="sale_status" />
      <JSONPrettyField source="title" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const StoreItemsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <ImageField source="image" />
      <TextField source="id" />
      <TextField source="amount" />
      <TextField source="currency" />
      <TextField source="sale_status" />
      <TextField source="limit_per_order" />
      <TextField source="sale_end_date" />
      <TextField source="sale_start_date" />
      <TextField source="shipping_date" />
      <UrlField source="url" />
      <JSONPrettyField source="title" />
      <JSONPrettyField source="description" />
      <TextField source="category_en" />
      <TextField source="category_jp" />
      <TextField source="product_type_en" />
      <TextField source="product_type_jp" />
      <TextField source="anime_title_en" />
      <TextField source="anime_title_jp" />
      <TextField source="inserted_at" />
      <TextField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

const StoreItemsEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <UrlField source="url" />
      <SelectInput source="sale_status" choices={[
        { id: 'IN_STOCK', name: 'IN_STOCK' },
        { id: 'PRE_ORDER', name: 'PRE_ORDER' },
        { id: 'SOLD_OUT', name: 'SOLD_OUT' }
      ]} />
      <JSONPrettyInput source="title" />
      <TextInput source="amount" />
      <TextInput source="currency" />
      <SelectInput source="category_en" choices={[
        { id: 'Apparel', name: 'Apparel' },
        { id: 'Art / Toys', name: 'Art / Toys' },
        { id: 'Figures', name: 'Figures' },
        { id: 'Media', name: 'Media' },
        { id: 'Zakka', name: 'Zakka' }
      ]} />
      <SelectInput source="category_jp" choices={[
        { id: 'アパレル', name: 'アパレル' },
        { id: 'グッズ / おもちゃ', name: 'グッズ / おもちゃ' },
        { id: 'フィギュア', name: 'フィギュア' },
        { id: 'メディア', name: 'メディア' },
        { id: '雑貨', name: '雑貨' }
      ]} />
      <SelectInput source="product_type_en" choices={[
        { id: 'Art Print', name: 'Art Print' },
        { id: 'Badge', name: 'Badge' },
        { id: 'Bag', name: 'Bag' },
        { id: 'Blu-ray', name: 'Blu-ray' },
        { id: 'Body Pillow', name: 'Body Pillow' },
        { id: 'Book', name: 'Book' },
        { id: 'Bracelet', name: 'Bracelet' },
        { id: 'Calendar', name: 'Calendar' },
        { id: 'Cards', name: 'Cards' },
        { id: 'CD', name: 'CD' },
        { id: 'Character Sleeve', name: 'Character Sleeve' },
        { id: 'Charm', name: 'Charm' },
        { id: 'Cu-Poche', name: 'Cu-Poche' },
        { id: 'Cup', name: 'Cup' },
        { id: 'Cushion', name: 'Cushion' },
        { id: 'Desktop Army', name: 'Desktop Army' },
        { id: 'Dress', name: 'Dress' },
        { id: 'Figure', name: 'Figure' },
        { id: 'File', name: 'File' },
        { id: 'Game', name: 'Game' },
        { id: 'Hat', name: 'Hat' },
        { id: 'Jacket', name: 'Jacket' },
        { id: 'Keychain', name: 'Keychain' },
        { id: 'Magazine', name: 'Magazine' },
        { id: 'Mascot', name: 'Mascot' },
        { id: 'Mobile Battery', name: 'Mobile Battery' },
        { id: 'Model', name: 'Model' },
        { id: 'Necklace', name: 'Necklace' },
        { id: 'Nendoroid', name: 'Nendoroid' },
        { id: 'Pants', name: 'Pants' },
        { id: 'Patch', name: 'Patch' },
        { id: 'Pen', name: 'Pen' },
        { id: 'Pencil', name: 'Pencil' },
        { id: 'Phone Case', name: 'Phone Case' },
        { id: 'Pitanui', name: 'Pitanui' },
        { id: 'Plush toy', name: 'Plush toy' },
        { id: 'Poster', name: 'Poster' },
        { id: 'Skirt', name: 'Skirt' },
        { id: 'Sticker', name: 'Sticker' },
        { id: 'T-shirt', name: 'T-shirt' },
        { id: 'Tapestry', name: 'Tapestry' },
        { id: 'Towel', name: 'Towel' },
        { id: 'Toy', name: 'Toy' },
        { id: 'Toy Car', name: 'Toy Car' },
        { id: 'Wallet', name: 'Wallet' }
      ]} />
      <SelectInput source="product_type_jp" choices={[
        { id: 'アート作品', name: 'アート作品' },
        { id: 'バッジ', name: 'バッジ' },
        { id: 'バッグ', name: 'バッグ' },
        { id: 'Blu-ray', name: 'Blu-ray' },
        { id: '抱き枕', name: '抱き枕' },
        { id: '書籍', name: '書籍' },
        { id: '腕輪', name: '腕輪' },
        { id: 'カレンダー', name: 'カレンダー' },
        { id: 'カード', name: 'カード' },
        { id: 'CD', name: 'CD' },
        { id: 'キャラクタースリーブ', name: 'キャラクタースリーブ' },
        { id: 'お守り', name: 'お守り' },
        { id: 'カバーホルダー', name: 'カバーホルダー' },
        { id: 'キューポッシュ', name: 'キューポッシュ' },
        { id: 'カップ', name: 'カップ' },
        { id: 'クッション', name: 'クッション' },
        { id: 'デスクトップ アーミー', name: 'デスクトップ アーミー' },
        { id: 'ドレス', name: 'ドレス' },
        { id: 'フィギュア', name: 'フィギュア' },
        { id: 'ファイル', name: 'ファイル' },
        { id: 'ゲーム', name: 'ゲーム' },
        { id: '帽子/ハット', name: '帽子/ハット' },
        { id: '上衣', name: '上衣' },
        { id: 'キーホルダー', name: 'キーホルダー' },
        { id: '雑誌', name: '雑誌' },
        { id: 'マスコット', name: 'マスコット' },
        { id: '充電器', name: '充電器' },
        { id: '模型', name: '模型' },
        { id: '首輪', name: '首輪' },
        { id: 'ねんどろいど', name: 'ねんどろいど' },
        { id: 'ズボン', name: 'ズボン' },
        { id: 'パッチ', name: 'パッチ' },
        { id: 'ペン', name: 'ペン' },
        { id: '鉛筆', name: '鉛筆' },
        { id: 'スマホケース', name: 'スマホケース' },
        { id: 'ピタヌイ', name: 'ピタヌイ' },
        { id: 'ぬいぐるみ', name: 'ぬいぐるみ' },
        { id: 'ポスター', name: 'ポスター' },
        { id: 'スカート', name: 'スカート' },
        { id: 'ステッカー', name: 'ステッカー' },
        { id: 'ティーシャツ', name: 'ティーシャツ' },
        { id: 'タペストリー', name: 'タペストリー' },
        { id: 'タオル', name: 'タオル' },
        { id: '玩具', name: '玩具' },
        { id: 'くるま', name: 'くるま' },
        { id: '財布', name: '財布' }
      ]} />
      <TextInput source="anime_title_en" fullWidth />
      <TextInput source="anime_title_jp" fullWidth />
    </SimpleForm>
  </Edit>
);

export default {
  list: StoreItemsList,
  show: StoreItemsShow,
  edit: StoreItemsEdit
};