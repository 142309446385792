import request from "superagent";

export const retrieveUser = async creatorId => {
  const apiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/users`

  try {
    const result = await request.get(`${apiUrl}?id=eq.${creatorId}`)
      .set("Content-Type", "application/json")
      .set("apikey", process.env.REACT_APP_API_KEY);

    const { body } = result;
    if (!body || body.length <= 0) return null;
    return body[0];
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const retrieveArtworkFromTokenId = async tokenId => {
  const apiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/tokens`

  try {
    const result = await request.get(`${apiUrl}?select=artworks.artwork_id(*)&id=eq.${tokenId}`)
      .set("Content-Type", "application/json")
      .set("apikey", process.env.REACT_APP_API_KEY);

    const { body } = result;
    if (!body || body.length <= 0) return null;
    return body[0].artworks;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const retrieveMerchPunkItemFromTokenId = async tokenId => {
  const apiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/tokens`

  try {
    const result = await request.get(`${apiUrl}?select=merch:merchpunk_item_id(*)&id=eq.${tokenId}`)
      .set("Content-Type", "application/json")
      .set("apikey", process.env.REACT_APP_API_KEY);

    const { body } = result;
    if (!body || body.length <= 0) return null;
    return body[0].merch;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const retrieveTransaction = async txId => {
  const apiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/transactions`

  try {
    const result = await request.get(`${apiUrl}?id=eq.${txId}`)
      .set("Content-Type", "application/json")
      .set("apikey", process.env.REACT_APP_API_KEY);

    const { body } = result;
    if (!body || body.length <= 0) return null;
    return body[0];
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const retrieveOrderInvoice = async orderId => {
  const apiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/merchpunk_shipping_invoices`

  try {
    const result = await request.get(`${apiUrl}?merchpunk_order_id=eq.${orderId}`)
      .set("Content-Type", "application/json")
      .set("apikey", process.env.REACT_APP_API_KEY);

    const { body } = result;
    if (!body || body.length <= 0) return null;
    return body[0];
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const sendMail = async (data) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/email-service/send-email`;

  try {
    await request.post(apiUrl)
      .set("Content-Type", "application/json")
      .set("x-api-key", process.env.REACT_APP_EMAIL_SERVICE_KEY)
      .send(data);

    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const mintToken = async (dataToSend) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/token-manager/mint`;

  try {
    const result = await request.post(apiUrl)
      .set("Content-Type", "application/json")
      .set("x-api-key", process.env.REACT_APP_TOKEN_MANAGER_SERVICE_KEY)
      .send(dataToSend);

    const { status } = result.body;
    if (status !== 200) return false;
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const transferToken = async (dataToSend) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/token-manager/transfer`;

  try {
    const result = await request.post(apiUrl)
      .set("Content-Type", "application/json")
      .set("x-api-key", process.env.REACT_APP_TOKEN_MANAGER_SERVICE_KEY)
      .send(dataToSend);

    const { status } = result.body;
    if (status !== 200) return false;
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

export const generateShortenUrl = async (originalUrl, shortenPath, title = null) => {
  try {
    const reqBody = {
      url: originalUrl,
      path: shortenPath,
      title
    }
    const url = `${process.env.REACT_APP_API_ENDPOINT}/printful-service/shorten`;
    const response = await request.post(url)
      .set("Content-Type", "application/json")
      .set("authorization", process.env.REACT_APP_API_KEY)
      .send(reqBody);
    console.log(response);
    const { shortURL } = response.body.data;
    return shortURL;
  } catch (e) {
    console.log("generateShortenUrl error", e);
    return null;
  }
}