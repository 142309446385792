import request from "superagent";

export const retrieveStripeCustomer = async userId => {
  const apiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/stripe_customer_ids`

  try {
    const result = await request.get(`${apiUrl}?user_id=eq.${userId}`)
      .set("Content-Type", "application/json")
      .set("Accept", "application/vnd.pgrst.object+json")
      .set("apikey", process.env.REACT_APP_API_KEY);

    const { body } = result;
    return body;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const createStripeCustomer = async (user) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/stripe-service/customer`;
  const { id, email, username, lang } = user;
  const data = { id, email, name: username, lang };
  try {
    const result = await request.post(`${apiUrl}`)
      .set("Content-Type", "application/json")
      .set("apikey", process.env.REACT_APP_API_KEY)
      .send(data);

    const { body } = result;
    return body;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export const createStripeInvoice = async (customerId, orderId, currency, shipInJpAmount, shipOutsideJpAmount) => {
  const apiUrl = `${process.env.REACT_APP_API_ENDPOINT}/stripe-service/invoice/mp-shipping`;
  // convert to cent value for USD
  const finalShipInJpAmount = currency === "usd" ? Math.ceil(shipInJpAmount * 100) : shipInJpAmount;
  const finalShipOutsideJpAmount = currency === "usd" ? Math.ceil(shipOutsideJpAmount * 100) : shipOutsideJpAmount;

  const data = {
    "customer_id": customerId,
    "order_id": orderId,
    "currency": currency,
    "shipping_in_jp_amount": finalShipInJpAmount,
    "shipping_outside_jp_amount": finalShipOutsideJpAmount
  };
  try {
    const result = await request.post(`${apiUrl}`)
      .set("Content-Type", "application/json")
      .set("apikey", process.env.REACT_APP_API_KEY)
      .send(data);

    const { body } = result;
    return body;
  } catch (err) {
    console.log(err);
    return null;
  }
}