import React from 'react';
import {
  Button,
  useNotify
} from 'react-admin';
import { retrieveUser, sendMail } from "../lib/request";
import EmailIcon from '@material-ui/icons/Email';

const RequestMoreInfoButton = ({ record }) => {
  const notify = useNotify();

  const onSendEmail = async () => {
    const { creator_id: creatorId, slug } = record;
    const creator = await retrieveUser(creatorId);
    // console.log(creator);
    if (!creator) {
      notify('Error: retrieve creator failed', 'error');
      return;
    }

    const { email, username } = creator;
    const data = {
      "templateName": "en_character_traits_for_artwork",
      "sendTo": email,
      "data": {
        "username": `@${username}`,
        "artwork-name": slug
      }
    }
    const sendMailSuccess = await sendMail(data);

    if (!sendMailSuccess) {
      notify('Error: send notification email failed', 'error');
    } else {
      notify('Request info email sent');
    }
  }

  return (
    <Button onClick={onSendEmail} label="Send Request Info Email">
      <EmailIcon />
    </Button>
  );
}

export default RequestMoreInfoButton;