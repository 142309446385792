import React from 'react';
import { Filter, List, Datagrid, TextField, ReferenceField } from 'react-admin';
import { EditButton, SimpleForm, TextInput, DateTimeInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { Show, SimpleShowLayout, NumberInput } from 'react-admin';
import { Create, Edit } from 'react-admin';
import CurrentDateTimeInput from '../components/CurrentDateTimeInput'

const ContractFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Filter by creator id" source="creator_id" alwaysOn />
    <TextInput label="Filter by version" source="version" alwaysOn />
  </Filter>
);
const ContractList = (props) => (
  <List {...props} sort={{ field: 'inserted_at', order: 'DESC' }} filters={<ContractFilter />}>
    <Datagrid>
      <TextField source="id" />
      <ReferenceField label="Creator" source="creator_id" reference="users" link="show">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="address" />
      <TextField source="network" />
      <TextField source="version" />
      <EditButton />
    </Datagrid>
  </List>
);
const ContractShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="address" />
      <TextField source="network" />
      <TextField source="version" />
    </SimpleShowLayout>
  </Show>
);

const ContractEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <ReferenceInput label="Creator" source="creator_id" filterToQuery={searchText => ({ username: searchText })} reference="users" allowEmpty>
        <AutocompleteInput optionText={choice => `${choice.id}: ${choice.username}`} />
      </ReferenceInput>
      <TextInput source="address" />
      <TextInput source="network" />
      <TextInput source="version" />
      <TextInput source="contract_owner" />
      <TextInput source="license_manager" />
      <TextInput source="github_hash" />
      <TextInput source="contract_created" />
      <TextInput source="inserted_at" disabled />
      <CurrentDateTimeInput source="updated_at" />
    </SimpleForm>
  </Edit>
);

const ContractCreate = (props) => (
  <Create title="Create a Post" {...props}>
    <SimpleForm>
      <ReferenceInput label="Creator" source="creator_id" filterToQuery={searchText => ({ username: searchText })} reference="users" allowEmpty>
        <AutocompleteInput optionText={choice => `${choice.id}: ${choice.username}`} />
      </ReferenceInput>
      <TextInput source="address" />
      <TextInput source="network" />
      <TextInput source="version" />
      <TextInput source="contract_owner" />
      <TextInput source="license_manager" />
      <TextInput source="github_hash" />
      <TextInput source="contract_created" />
      <DateTimeInput source="inserted_at" defaultValue={new Date()} />
      <DateTimeInput source="updated_at" defaultValue={new Date()} />
    </SimpleForm>
  </Create>
);

export default {
  list: ContractList,
  create: ContractCreate,
  edit: ContractEdit,
  show: ContractShow
};