import React from 'react';
import { NumberField } from 'react-admin';

const CurrencyField = (props) => {
  const { record } = props;
  const { price } = record;
  const isFiatPayment = price < 10000000;
  const priceValue = isFiatPayment ? price/100 : price/1000000000000000000;
  const options = isFiatPayment ? {style: 'currency',currency: 'USD'} : {style: 'currency',currency: 'ETH'}

  const myProps = {...props, record: {...props.record, price: priceValue} }
  return (
    <NumberField {...myProps} options={options} />
  )
};

CurrencyField.defaultProps = {
  addLabel: true,
};

export default CurrencyField;