import React, { useState, Fragment } from 'react';
import {
  Button,
  SaveButton,
  NumberInput,
  useNotify
} from 'react-admin';
import { Form } from 'react-final-form'
import { mintToken } from "../lib/request";
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const MintTokenButton = ({ record }) => {
  const { starting_price: priceUsd, starting_price_jpy: priceJpy } = record || {};
  const notify = useNotify();

  const [showDialog, setShowDialog] = useState(false);
  const [mintingMessage, setMintingMessage] = useState(null);

  const onOpenDialog = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const sleep = (ms) => {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }

  const onMintToken = async values => {
    const { id: artwork_id, creator_id } = record;
    const { priceUsd, priceJpy, numberOfToken } = values;

    const newTokenRequestData = {
      owner_id: creator_id,
      artwork_id,
      current_price_usd: priceUsd,
      current_price_jpy: priceJpy
    }

    for (let i = 0; i < numberOfToken; i += 1) {
      const tokenNo = i + 1;
      const mintTokenSuccess = await mintToken(newTokenRequestData);
      if (!mintTokenSuccess) notify(`ERROR: Minted token no.${tokenNo} failed`, "error");

      setMintingMessage(`Minted token no.${tokenNo}`);
      // mint 1 token per 1 second
      await sleep(1000);
    }

    setShowDialog(false);
    setMintingMessage(null);
    notify('Minting token completed');
  };

  return (
    <Fragment>
      <Button onClick={onOpenDialog} label="Mint Token">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={onCloseDialog}
      >
        <DialogTitle>Mint Token</DialogTitle>
        <Form
          onSubmit={onMintToken}
          render={({ handleSubmit, submitting }) => {
            const saveButtonLabel = submitting ? (mintingMessage || "Minting...") : "Start Minting";
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <NumberInput source="priceUsd" defaultValue={priceUsd || 0} fullWidth />
                  <NumberInput source="priceJpy" defaultValue={priceJpy || 0} fullWidth />
                  <NumberInput source="numberOfToken" defaultValue={0} fullWidth />
                </DialogContent>
                <DialogActions>
                  <SaveButton
                    label={saveButtonLabel}
                    saving={submitting}
                    handleSubmitWithRedirect={event => { handleSubmit(event) }}
                  />
                  <Button label="Cancel" onClick={onCloseDialog}>
                    <IconCancel />
                  </Button>
                </DialogActions>
              </form>
            )
          }} />
      </Dialog>
    </Fragment>
  );
}

export default MintTokenButton;