import React from 'react';
import { TextInput } from 'react-admin';
import { useForm } from 'react-final-form';
import ImageUploadButton from './ImageUploadButton';
import NoImage from "../static/no-image.png"

const ImageUrlTextInput = (props) => {
  const { source } = props;
  const recordForm = useForm();

  const getS3CDNImageURL = (url, size) => {
    if (!url) return null;

    let result = url;
    result = result.replace("blockstudios.net/", "blockpunk.net/");
    result = result.replace("//img.", "//img-cdn.");
    if (size) result = result.replace(".net/", `.net/${size}/`);

    return result;
  };

  const getImageSrc = () => {
    const { initialValues, values, modified } = recordForm.getState();

    const { image_url: isImageModified } = modified;
    const initialImageSrc = initialValues[source];
    const latestImageSrc = values[source];
    let imageSrc = NoImage;

    if (isImageModified && latestImageSrc) imageSrc = getS3CDNImageURL(latestImageSrc, null);
    else if (initialImageSrc) imageSrc = getS3CDNImageURL(initialImageSrc, "300w");
    return imageSrc;
  }

  return (
    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
      <img src={getImageSrc()} alt="preview"
        style={{ width: "3rem", height: "3rem", objectFit: "contain", margin: "auto" }} />
      <TextInput {...props}
        style={{ flexGrow: 1, marginLeft: "1rem" }} />
      <ImageUploadButton {...props} />
    </div>
  )
}

export default ImageUrlTextInput;