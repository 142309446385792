import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
import { ShowButton, Show, SimpleShowLayout } from 'react-admin';
import { Filter, TextInput, SelectInput } from 'react-admin';
import JSONPrettyField from '../components/JSONPrettyField';

const ShippingInvoicesFilter = (props) => (
  <Filter {...props}>
    <TextInput source="stripe_invoice_id" alwaysOn />
    <SelectInput source="status" choices={[
      { id: 'OPEN', name: 'OPEN' },
      { id: 'PAID', name: 'PAID' },
      { id: 'VOID', name: 'VOID' }
    ]} alwaysOn />
  </Filter>
);

const ShippingInvoicesList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }} filters={<ShippingInvoicesFilter />}>
    <Datagrid>
      <TextField source="id" />
      <TextField source="status" />
      <TextField source="stripe_invoice_id" />
      <ReferenceField label="Order" source="merchpunk_order_id" reference="merchpunk_orders" link="show" allowEmpty>
        <TextField source="order_id" />
      </ReferenceField>
      <TextField source="inserted_at" />
      <ShowButton />
    </Datagrid>
  </List>
);

const ShippingInvoicesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Order" source="merchpunk_order_id" reference="merchpunk_orders" link="show" allowEmpty>
        <TextField source="order_id" />
      </ReferenceField>
      <TextField source="status" />
      <TextField source="stripe_charge_id" />
      <TextField source="stripe_invoice_id" />
      <TextField source="stripe_amount" />
      <TextField source="stripe_currency" />
      <JSONPrettyField source="metadata" />
      <TextField source="inserted_at" />
      <TextField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default {
  list: ShippingInvoicesList,
  show: ShippingInvoicesShow,
};