import React from 'react';
import compose from 'recompose/compose';
import MuiGridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import { Link } from 'react-router-dom';
import { linkToRecord } from 'ra-core';

const styles = theme => ({
  root: {
    margin: '5px',
  },
  gridList: {
    width: '100%',
    margin: '0 !important',
  },
  gridItem: {
    padding: "5px !important",
  },
  tileBar: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    background:
      'linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%)',
  },
  placeholder: {
    backgroundColor: theme.palette.grey[300],
    height: '100%',
  },
  price: {
    display: 'inline',
    fontSize: '1em',
  },
  link: {
    color: '#fff',
  },
});

const getColsForWidth = width => {
  if (width === 'xs') return 2;
  if (width === 'sm') return 3;
  if (width === 'md') return 4;
  if (width === 'lg') return 5;
  return 6;
};

const times = (nbChildren, fn) =>
  Array.from({ length: nbChildren }, (_, key) => fn(key));

const getCdnImageUrl = (raw_image_url) => {
  if (!raw_image_url) return "";

  if (raw_image_url.endsWith("png") || raw_image_url.endsWith("jpg") || raw_image_url.endsWith("jpeg")) {
    return raw_image_url.replace('.net/', '.net/300w/')
  }

  return raw_image_url;
}

const LoadingGridList = ({ width, classes, nbItems = 10 }) => (
  <div className={classes.root}>
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {' '}
      {times(nbItems, key => (
        <GridListTile key={key} className={classes.gridItem}>
          <div className={classes.placeholder} />
        </GridListTile>
      ))}
    </MuiGridList>
  </div>
);

const LoadedGridList = ({ classes, ids, data, basePath, width }) => (
  <div className={classes.root}>
    <MuiGridList
      cellHeight={180}
      cols={getColsForWidth(width)}
      className={classes.gridList}
    >
      {ids.map(id => (
        <GridListTile
          component={Link}
          key={id}
          to={`${linkToRecord(basePath, data[id].id)}/show`}
          className={classes.gridItem}
        >
          <img style={{ width: "100%", height: "100%", objectFit: "cover", backgroundColor: "lightgray" }}
            src={getCdnImageUrl(data[id].image_url)}
            alt={data[id].slug}
          />
          <GridListTileBar
            className={classes.tileBar}
            title={(data[id].slug) ? data[id].slug.replace(/-/g, ' ') : ''}
          />
        </GridListTile>
      ))}
    </MuiGridList>
  </div>
);

const GridList = ({ loaded, ...props }) =>
  loaded ? <LoadedGridList {...props} /> : <LoadingGridList {...props} />;

const enhance = compose(
  withWidth(),
  withStyles(styles)
);

export default enhance(GridList);