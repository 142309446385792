import React, { Component } from "react";
import auth0Client from "./lib/auth";

class AuthPage extends Component {
  componentDidMount() {
    // console.log(this.props);
    const { history: router } = this.props || {};
    const promise = auth0Client.handleAuthentication(router);
    promise.then(
      token => {
        // Promise is resolved
        localStorage.setItem('role', 'admin');
        localStorage.setItem('token', token);
        //console.log('login token', token);
        window.location.href = window.top.location.origin;
      },
      err => {
        // Promise is rejected
        // console.log(err);
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        router.push('/login');
      }
    );
  }

  render() {
    const style = {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      margin: "2rem",
      backgroundColor: 'white',
    }

    return (
      <div style={style}>
        The app will auto-redirect after authentication ...
      </div>
    );
  }
}

export default AuthPage;