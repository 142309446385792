import React from 'react';
import { List, Datagrid, TextField, ReferenceField } from 'react-admin';
import { ShowButton, Show, SimpleShowLayout } from 'react-admin';
import { Filter, NumberInput, ReferenceInput, AutocompleteInput } from 'react-admin';
// import { EditButton } from 'react-admin';
import CurrencyField from '../components/CurrencyField';
import JSONPrettyField from '../components/JSONPrettyField';

const OrderItemsFilter = (props) => (
  <Filter {...props}>
    <ReferenceInput source="merchpunk_order_id_id" reference="merchpunk_orders" filterToQuery={searchText => ({ order_id: searchText })} allowEmpty alwaysOn>
      <AutocompleteInput
        optionText={choice => choice.order_id}
      />
    </ReferenceInput>
    <NumberInput label="Filter by merch id" source="merchpunk_item_id_id" alwaysOn />
  </Filter>
);

const OrderItemsList = (props) => (
  <List {...props} sort={{ field: 'id', order: 'DESC' }} filters={<OrderItemsFilter />}>
    <Datagrid>
      <TextField label="Item name" source="metadata.item_title" />
      <ReferenceField label="Item Detail" source="merchpunk_item_id_id" reference="merchpunk_store_items" link="show" allowEmpty>
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField label="Order" source="merchpunk_order_id_id" reference="merchpunk_orders" link="show" allowEmpty>
        <TextField source="order_id" />
      </ReferenceField>
      <TextField label="Buy at" source="inserted_at" />
      <ShowButton />
      {/* <EditButton /> */}
    </Datagrid>
  </List>
);

const OrderItemsShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <CurrencyField source="payment_amount" />
      <TextField source="payment_currency" />
      <JSONPrettyField source="metadata" />
      <ReferenceField label="Merchpunk Item" source="merchpunk_item_id_id" reference="merchpunk_store_items" link="show" allowEmpty>
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField label="Order" source="merchpunk_order_id_id" reference="merchpunk_orders" link="show" allowEmpty>
        <TextField source="id" />
      </ReferenceField>
      <TextField source="inserted_at" />
      <TextField source="updated_at" />
    </SimpleShowLayout>
  </Show>
);

export default {
  list: OrderItemsList,
  show: OrderItemsShow,
};