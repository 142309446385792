import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { EditButton, SimpleForm, TextInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { ShowButton, Show, SimpleShowLayout, ReferenceField } from 'react-admin';
import { Create, Edit, Filter, NumberInput } from 'react-admin';
import JSONPrettyField from '../components/JSONPrettyField';
import JSONPrettyInput from '../components/JSONPrettyInput';

const ArtworkExtrasFilter = (props) => (
  <Filter {...props}>
    <NumberInput label="Filter by artwork id" source="artwork_id" alwaysOn />
  </Filter>
);

const ArtworkExtrasList = (props) => (
  <List {...props} filters={<ArtworkExtrasFilter />}>
    <Datagrid>
      <ReferenceField label="Artwork" source="artwork_id" reference="artworks" link="show" allowEmpty>
        <TextField source="slug" />
      </ReferenceField>
      <JSONPrettyField source="extras" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);

const ArtworkExtrasShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="artwork_id" />
      <JSONPrettyField source="extras" />
    </SimpleShowLayout>
  </Show>
);

const ArtworkExtrasEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="id" disabled />
      <ReferenceInput label="Artwork" source="artwork_id" filterToQuery={searchText => ({ slug: searchText })} reference="artworks" allowEmpty fullWidth>
        <AutocompleteInput optionText={choice => choice ? choice.slug : ""} options={{ fullWidth: true }} />
      </ReferenceInput>
      <JSONPrettyInput source="extras" />
    </SimpleForm>
  </Edit>
);

const ArtworkExtrasCreate = (props) => {
  return (
    <Create title="Link Artwork Badge" {...props}>
      <SimpleForm>
        <ReferenceInput label="Artwork" source="artwork_id" filterToQuery={searchText => ({ slug: searchText })} reference="artworks" allowEmpty fullWidth>
          <AutocompleteInput optionText={choice => choice.slug} allowEmpty options={{ fullWidth: true }} />
        </ReferenceInput>
        <JSONPrettyInput source="extras" label="Extras Json" defaultValue={[]} />
      </SimpleForm>
    </Create>
  )
};

export default {
  list: ArtworkExtrasList,
  create: ArtworkExtrasCreate,
  edit: ArtworkExtrasEdit,
  show: ArtworkExtrasShow
};