import React from 'react';
import { List, TextField, ReferenceField } from 'react-admin';
import { TextInput, DateTimeInput, ReferenceInput, AutocompleteInput } from 'react-admin';
import { required, Filter, ReferenceManyField, Datagrid } from 'react-admin';
import { TabbedForm, FormTab, ArrayInput, SimpleFormIterator, SelectInput } from 'react-admin';
import { Create, Edit, RadioButtonGroupInput, NumberInput, EditButton } from 'react-admin';
import { FormDataConsumer } from 'react-admin';
import GridList from './GridList';
import AddArtworkBadgeButton from './AddArtworkBadgeButton';
import withStyles from '@material-ui/core/styles/withStyles';
import ArtworkShow from './ArtworkShow';
import ImageUrlTextInput from '../components/ImageUrlTextInput';
import ImageThumbnailField from '../components/ImageThumbnailField';
import FileUploadButton from '../components/FileUploadButton';

/**
 * include === to do eq.keywords filter
 * else it will do ilike.*keywords*
 */
const ArtworkFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Filter by slug" source="slug" alwaysOn />
    <NumberInput label="Filter by creator id" source="creator_id" alwaysOn />
    <SelectInput source="type" alwaysOn choices={[
      { id: 'O', name: 'Original Art' },
      { id: 'F', name: 'Fan Art' },
      { id: 'M', name: 'Merchandise' },
      { id: 'P', name: 'Print' },
      { id: 'T', name: 'Tip Art' },
      { id: 'V', name: 'Video' },
    ]} />
    <SelectInput source="status" alwaysOn choices={[
      { id: '===PENDING', name: 'PENDING' },
      { id: '===ACTIVE', name: 'ACTIVE' },
      { id: '===DISABLED', name: 'DISABLED' },
    ]} />
  </Filter>
);
const ArtworkList = (props) => (
  <List
    {...props}
    perPage={25}
    sort={{ field: 'updated_at', order: 'DESC' }}
    filters={<ArtworkFilter />}
  >
    <GridList />
  </List>
);

const inputStyles = {
  fullWidth: {
    '& div:first-child': { width: '100%' }
  },
};

const ArtworkEdit = withStyles(inputStyles)(({ classes, ...props }) => (
  <Edit {...props}>
    <TabbedForm submitOnEnter={false}>
      <FormTab label="identity">
        <TextInput source="id" disabled />
        <ImageUrlTextInput source="image_url" />
        <TextInput source="slug" fullWidth />
        <ReferenceInput label="Creator" validate={required()} source="creator_id" filterToQuery={searchText => ({ username: searchText })} reference="users" fullWidth>
          <AutocompleteInput optionText={choice => choice ? `${choice.id}: ${choice.username}` : ""} options={{ fullWidth: true }} />
        </ReferenceInput>
        <ReferenceInput label="Licensor" source="licensor_id" filterToQuery={searchText => ({ username: searchText })} reference="users" allowEmpty fullWidth>
          <AutocompleteInput optionText={choice => choice ? `${choice.id}: ${choice.username}` : ""} options={{ fullWidth: true }} />
        </ReferenceInput>
        <RadioButtonGroupInput source="type" row={false} choices={[
          { id: 'O', name: 'Original Art' },
          { id: 'F', name: 'Fan Art' },
          { id: 'M', name: 'Merchandise' },
          { id: 'P', name: 'Print' },
          { id: 'T', name: 'Tip Art' },
          { id: 'V', name: 'Video' },
        ]} />
        <RadioButtonGroupInput source="sale_status" row={false} choices={[
          { id: 'LIMITED', name: 'Limited Quantity' },
          { id: 'UNLIMITED', name: 'Unlimited (Preorder)' },
          { id: 'COMING_SOON', name: 'Coming Soon' },
          { id: 'GIFT', name: 'Extra Gift' }
        ]} />
      </FormTab>
      <FormTab label="sale">
        <RadioButtonGroupInput source="status" row={false} choices={[
          { id: 'PENDING', name: 'Pending' },
          { id: 'ACTIVE', name: 'Active' },
          { id: 'DISABLED', name: 'Disabled' },
        ]} />
        <NumberInput source="total_in_collection" fullWidth />
        <NumberInput source="starting_price" fullWidth />
        <NumberInput source="starting_price_jpy" fullWidth />
      </FormTab>
      <FormTab label="info">
        <FormDataConsumer>
          {({ formData, ...rest }) => {

            if (formData.type && formData.type === "M") {
              return <FileUploadButton {...rest} />
            }
          }}
        </FormDataConsumer>
        <ArrayInput source="attr_data" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="key" />
            <TextInput source="lang" />
            <TextInput source="value" multiline />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="badges" path="badges">
        <ReferenceManyField reference="artworks_badges" target="artwork_id" addLabel={false} fullWidth>
          <Datagrid>
            <TextField source="badge_id" />
            <ReferenceField label="Key" source="badge_id" reference="badges">
              <TextField source="key" />
            </ReferenceField>
            <ReferenceField label="Image" source="badge_id" reference="badges">
              <ImageThumbnailField className="avatar" source="image_url" title="image" />
            </ReferenceField>
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
        <AddArtworkBadgeButton />
      </FormTab>
      <FormTab label="misc">
        <TextInput source="inserted_at" disabled />
        <DateTimeInput source="updated_at" />
      </FormTab>
    </TabbedForm>
  </Edit>
));

const ArtworkCreate = withStyles(inputStyles)(({ classes, ...props }) => (
  <Create title="Create an Artwork" {...props}>
    <TabbedForm submitOnEnter={false} initialValues={{ image_is_contain: false }}>
      <FormTab label="identity">
        <ImageUrlTextInput source="image_url" />
        <TextInput source="slug" validate={required()} fullWidth />
        <ReferenceInput label="Creator" validate={required()} source="creator_id" filterToQuery={searchText => ({ username: searchText })} reference="users" fullWidth>
          <AutocompleteInput optionText={choice => choice ? `${choice.id}: ${choice.username}` : ""} options={{ fullWidth: true }} />
        </ReferenceInput>
        <ReferenceInput label="Licensor" source="licensor_id" filterToQuery={searchText => ({ username: searchText })} reference="users" allowEmpty fullWidth>
          <AutocompleteInput optionText={choice => choice ? `${choice.id}: ${choice.username}` : ""} options={{ fullWidth: true }} />
        </ReferenceInput>
        <RadioButtonGroupInput source="type" row={false} defaultValue="O" choices={[
          { id: 'O', name: 'Original Art' },
          { id: 'F', name: 'Fan Art' },
          { id: 'M', name: 'Merchandise' },
          { id: 'P', name: 'Print' },
          { id: 'T', name: 'Tip Art' },
          { id: 'V', name: 'Video' },
        ]} />
        <RadioButtonGroupInput source="sale_status" row={false} defaultValue="LIMITED" choices={[
          { id: 'LIMITED', name: 'Limited Quantity' },
          { id: 'UNLIMITED', name: 'Unlimited (Preorder)' },
          { id: 'COMING_SOON', name: 'Coming Soon' },
          { id: 'GIFT', name: 'Extra Gift' }
        ]} />
      </FormTab>
      <FormTab label="sale">
        <RadioButtonGroupInput source="status" row={false} defaultValue="PENDING" choices={[
          { id: 'PENDING', name: 'Pending' },
          { id: 'ACTIVE', name: 'Active' },
          { id: 'DISABLED', name: 'Disabled' },
        ]} />
        <NumberInput source="total_in_collection" fullWidth />
        <NumberInput source="starting_price" fullWidth />
        <NumberInput source="starting_price_jpy" fullWidth />
      </FormTab>
      <FormTab label="info">
        <FormDataConsumer>
          {({ formData, ...rest }) => {

            if (formData.type && formData.type === "M") {
              return <FileUploadButton {...rest} />
            }
          }}
        </FormDataConsumer>
        <ArrayInput source="attr_data" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="key" />
            <TextInput source="lang" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="misc">
        <DateTimeInput source="inserted_at" defaultValue={new Date()} />
        <DateTimeInput source="updated_at" defaultValue={new Date()} />
      </FormTab>
    </TabbedForm>
  </Create>
));

export default {
  list: ArtworkList,
  create: ArtworkCreate,
  edit: ArtworkEdit,
  show: ArtworkShow
};