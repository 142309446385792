import React from 'react';
import {
  Button,
  useRefresh,
  useNotify
} from 'react-admin';
import { retrieveUser, sendMail } from "../lib/request";
import request from "superagent";
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const ApproveButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onApprove = async () => {
    // console.log(record);
    if (record.status === 'ACTIVE') {
      notify('Error: artwork already approved', 'warning');
      return;
    }

    const { id, creator_id: creatorId, slug } = record;
    const creator = await retrieveUser(creatorId);
    // console.log(creator);
    if (!creator) {
      notify('Error: retrieve creator failed', 'error');
      return;
    }

    const updatedRecord = { ...record, status: 'ACTIVE' };
    const apiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/artworks`

    request.patch(`${apiUrl}?id=eq.${id}`)
      .set("Content-Type", "application/json")
      .set("apikey", process.env.REACT_APP_API_KEY)
      .send(updatedRecord)
      .end(async (err, res) => {
        if (err) {
          console.log(err);
          notify('Error: artwork not approved', 'error');
          return;
        }

        const { email, username } = creator;
        const artworkUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/en/collection/${slug}`;
        const data = {
          "templateName": "en_artwork_is_live",
          "sendTo": email,
          "data": {
            "username": `@${username}`,
            "link-to-the-artwork": artworkUrl
          }
        }
        const sendMailSuccess = await sendMail(data);

        if (!sendMailSuccess) {
          notify('Error: send notification email failed', 'error');
        } else {
          notify('Artwork approved');
          refresh();
        }
      });
  }

  return (
    <Button onClick={onApprove} label="Approve">
      <ThumbUpIcon />
    </Button>
  );
};

export default ApproveButton;