import React, { useState, Fragment } from 'react';
import {
  Button,
  SaveButton,
  NumberInput,
  RadioButtonGroupInput,
  required,
  useRefresh,
  useNotify
} from 'react-admin';
import { Form } from 'react-final-form'
import { retrieveUser, retrieveOrderInvoice } from "../lib/request";
import { retrieveStripeCustomer, createStripeCustomer, createStripeInvoice } from "../lib/stripeRequest";
import request from "superagent";
import EmailIcon from '@material-ui/icons/Email';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const SendShippingInvoiceButton = ({ record }) => {
  const { id, status, order_id: orderId, stripe_currency: originPaymentCurrency } = record || {};
  const [showDialog, setShowDialog] = useState(false);
  const [existedInvoice, setExistedInvoice] = useState(null);
  const notify = useNotify();
  const refresh = useRefresh();
  const isDisabled = status !== "ORDER_COMPLETE";
  const exsitedShippingInvoiceId = existedInvoice ? existedInvoice.stripe_invoice_id : null;
  const sendInvoiceDisabled = exsitedShippingInvoiceId !== null;
  const defaultCurrency = originPaymentCurrency ? originPaymentCurrency.toLowerCase() : "";

  const onOpenDialog = async () => {
    setShowDialog(true);

    const existedInvoice = await retrieveOrderInvoice(id);
    setExistedInvoice(existedInvoice);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const onSendInvoice = async values => {
    const { user_id: buyerId } = record;
    const buyer = await retrieveUser(buyerId);
    if (!buyer) {
      notify('Error: retrieve buyer failed', 'error');
      return;
    }

    let stripeCustomerId = undefined;
    const buyerStripeCustomer = await retrieveStripeCustomer(buyerId);
    if (!buyerStripeCustomer) {
      // create stripe customer for this buyer
      const stripeCustomerResult = await createStripeCustomer(buyer);
      // console.log("CREATE stripeCustomer", stripeCustomerResult);
      stripeCustomerId = stripeCustomerResult.data.id;
    } else {
      stripeCustomerId = buyerStripeCustomer.customer_id;
    }

    // console.log("stripeCustomerId", stripeCustomerId);
    if (!stripeCustomerId) {
      notify('Error: retrieve buyer stripe customer id failed', 'error');
      return;
    }

    const { currency, shippingInJapanAmount, shippingOutsideJapanAmount } = values;
    const createInvoiceResult = await createStripeInvoice(stripeCustomerId, orderId, currency, shippingInJapanAmount, shippingOutsideJapanAmount);
    if (!createInvoiceResult) {
      notify('Error: send shipping invoice failed', 'error');
    }
    console.log("createInvoiceResult", createInvoiceResult);
    const { data: invoiceData } = createInvoiceResult
    const { id: invoiceId, currency: invoiceCurrency, amount_due: invoiceAmount, customer: invoiceCustomer, lines } = invoiceData;
    const { customer_email: invoiceCustomerEmail, hosted_invoice_url: invoiceUrl, invoice_pdf: invoicePdf, due_date: invoiceDueDate } = invoiceData;
    if (!invoiceId) {
      notify('Error: shipping invoice Id not exists', 'error');
    } else {
      const requestData = {
        status: "OPEN",
        stripe_currency: invoiceCurrency,
        stripe_amount: invoiceAmount,
        stripe_invoice_id: invoiceId,
        stripe_charge_id: invoiceCustomer,
        metadata: {
          customer_email: invoiceCustomerEmail,
          url: invoiceUrl,
          pdf: invoicePdf,
          due_date: invoiceDueDate,
          lines
        },
        inserted_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        merchpunk_order_id: id
      }

      request.post(`${process.env.REACT_APP_API_ADMIN_ENDPOINT}/merchpunk_shipping_invoices`)
        .set("Accept", "application/vnd.pgrst.object+json")
        .set("apikey", process.env.REACT_APP_API_KEY)
        .send(requestData)
        .end(async (err, res) => {
          if (err) {
            console.log(err);
            notify('Error: update transaction failed', 'error');
            return;
          }

          notify('Send shipping invoice successfully');
          setShowDialog(false);
          refresh();
        });
    }
  }

  return (
    <Fragment>
      <Button onClick={onOpenDialog} disabled={isDisabled} label="Send Shipping Invoice">
        <EmailIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={onCloseDialog}
        aria-label="Send MerchPunk Shipping Invoice"
      >
        <DialogTitle>Send MerchPunk Shipping Invoice</DialogTitle>
        <Form
          onSubmit={onSendInvoice}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <RadioButtonGroupInput source="currency" validate={required()} defaultValue={defaultCurrency} choices={[
                    { id: 'usd', name: 'USD' },
                    { id: 'jpy', name: 'JPY' }
                  ]} />
                  <NumberInput source="shippingInJapanAmount" defaultValue="" validate={required()} fullWidth />
                  <NumberInput source="shippingOutsideJapanAmount" defaultValue="" validate={required()} fullWidth />
                  {exsitedShippingInvoiceId && <p className="warning">{`Existed Shipping Invoice Id: ${exsitedShippingInvoiceId}`}</p>}
                </DialogContent>
                <DialogActions>
                  <SaveButton
                    label="Send Invoice"
                    saving={submitting}
                    handleSubmitWithRedirect={event => { handleSubmit(event) }}
                    disabled={sendInvoiceDisabled} />
                  <Button label="Cancel" onClick={onCloseDialog}>
                    <IconCancel />
                  </Button>
                </DialogActions>
              </form>
            )
          }} />
      </Dialog>
    </Fragment>
  )
}

export default SendShippingInvoiceButton;