import React, { useState, Fragment } from 'react';
import nanoid from "nanoid";
import request from "superagent";
import { Form, useForm } from 'react-final-form';
import {
  Button,
  SaveButton,
  FileInput,
  useNotify,
  FileField
} from 'react-admin';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const FileUploadButton = ({ source }) => {
  const notify = useNotify();
  const recordForm = useForm();

  const [showDialog, setShowDialog] = useState(false);
  const [uploadedUrl, setUploadedUrl] = useState(null);

  const onShowDialog = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const appendUniqueName = (file) => {
    const ext = file.name.split(".").pop();
    const randomId = nanoid();
    file.uniqueName = `${randomId}.${ext}`;
    return file;
  }

  const onUpload = values => {
    let { file } = values;
    // console.log(values);

    file = appendUniqueName(file.rawFile);
    const fileName = file.uniqueName;
    const getSignedUrl = `${process.env.REACT_APP_API_ENDPOINT}/image/sign/${fileName}`;
    // console.log(file);
    // console.log(getSignedUrl);
    request.get(getSignedUrl).end((err, res) => {
      if (err) {
        console.log("err", err);
        notify(err.message, 'error');
        return;
      }

      // console.log(res);
      const signedUrl = res.body.url;
      // console.log('signedUrl', signedUrl);
      const mime = res.body.mime;
      request.put(signedUrl)
        .set("Content-Type", mime)
        .send(file)
        .end((err, res) => {
          if (err) {
            console.log("err", err);
            notify(err.message, 'error');
            return;
          }

          const fileUrl = `https://img-cdn.blockpunk.net/${fileName}`;
          if (source) {
            recordForm.change(source, fileUrl);
            setShowDialog(false);
          } else {
            setUploadedUrl(fileUrl);
          }
        });
    });
  }

  return (
    <Fragment>
      <Button onClick={onShowDialog} label="Upload File">
        <IconContentAdd />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={onCloseDialog}
      >
        <DialogTitle>Upload File</DialogTitle>
        <Form
          onSubmit={onUpload}
          render={({ handleSubmit, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <FileInput source="file" label="" accept=".pdf, .epub, .mobi, .zip, audio/*, image/*, video/*" placeholder={<p>Drop your file here</p>}>
                    <FileField source="src" title="file source" />
                  </FileInput>
                  {uploadedUrl && (
                    <div className="uploaded-url">
                      <p>Uploaded URL</p>
                      <p>{uploadedUrl}</p>
                    </div>
                  )}
                </DialogContent>
                <DialogActions>
                  <SaveButton
                    label="Upload"
                    saving={submitting}
                    handleSubmitWithRedirect={event => { handleSubmit(event) }}
                  />
                  <Button label="Cancel" onClick={onCloseDialog}>
                    <IconCancel />
                  </Button>
                </DialogActions>
              </form>
            )
          }} />
      </Dialog>
    </Fragment>
  );
}

export default FileUploadButton;

// class FileUploadButton extends Component {
//   state = {
//     error: false,
//     showDialog: false,
//     uploadedUrl: null
//   };

//   handleClick = () => {
//     this.setState({ showDialog: true });
//   };

//   handleCloseClick = () => {
//     this.setState({ showDialog: false });
//   };

//   handleSaveClick = () => {
//     document
//       .getElementById('upload-file')
//       .dispatchEvent(new Event('submit', { cancelable: true }))
//   };

//   handleSubmit = values => {
//     const { showNotification, source } = this.props;
//     const form = useForm();
//     let { file } = values;
//     // console.log(values);
//     // console.log(this.props);

//     file = this.appendUniqueName(file.rawFile);
//     const fileName = file.uniqueName;
//     const getSignedUrl = `${process.env.REACT_APP_API_ENDPOINT}/image/sign/${fileName}`;
//     // console.log(file);
//     // console.log(getSignedUrl);
//     request.get(getSignedUrl).end((err, res) => {
//       if (err) {
//         console.log("err", err);
//         showNotification(err.message, 'error');
//         return;
//       }

//       // console.log(res);
//       const signedUrl = res.body.url;
//       // console.log('signedUrl', signedUrl);
//       const mime = res.body.mime;
//       request.put(signedUrl)
//         .set("Content-Type", mime)
//         .send(file)
//         .end((err, res) => {
//           if (err) {
//             console.log("err", err);
//             showNotification(err.message, 'error');
//             return;
//           }

//           const imageUrl = `https://img-cdn.blockpunk.net/${fileName}`;
//           if (source) {
//             form.change(source, imageUrl);
//             this.setState({ showDialog: false });
//           } else {
//             this.setState({ uploadedUrl: imageUrl });
//           }
//         });
//     });
//   };

//   appendUniqueName(file) {
//     const ext = file.name.split(".").pop();
//     const randomId = nanoid();
//     file.uniqueName = `${randomId}.${ext}`;
//     return file;
//   }

//   render() {
//     const { showDialog, uploadedUrl } = this.state;
//     const { isSubmitting } = this.props;

//     return (
//       <Fragment>
//         <Button onClick={this.handleClick} label="Upload File">
//           <IconContentAdd />
//         </Button>
//         <Dialog
//           fullWidth
//           open={showDialog}
//           onClose={this.handleCloseClick}
//           aria-label="Upload File"
//         >
//           <DialogTitle>Upload File</DialogTitle>
//           <DialogContent>
//             <SimpleForm
//               // We override the react-final-form name to avoid collision with the react-admin main form
//               form="upload-file"
//               // We override the react-final-form onSubmit prop to handle the submission ourselves
//               onSubmit={this.handleSubmit}
//               // We want no toolbar at all as we have our modal actions
//               toolbar={null}
//             >
//               <FileInput source="file" label="" accept=".pdf, .epub, .mobi, .zip, audio/*, image/*" placeholder={<p>Drop your file here</p>}>
//                 <FileField source="src" title="file source" />
//               </FileInput>
//               {uploadedUrl && (
//                 <div className="uploaded-url">
//                   <p>Uploaded URL</p>
//                   <p>{uploadedUrl}</p>
//                 </div>
//               )}
//             </SimpleForm>
//           </DialogContent>
//           <DialogActions>
//             <SaveButton
//               label="Upload"
//               saving={isSubmitting}
//               onClick={this.handleSaveClick}
//             />
//             <Button label="Cancel" onClick={this.handleCloseClick}>
//               <IconCancel />
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </Fragment>
//     );
//   }
// }

// const mapStateToProps = state => ({
//   // isSubmitting: isSubmitting('upload-file')(state)
// });

// const mapDispatchToProps = {
//   showNotification
// };

// export default connect(mapStateToProps, mapDispatchToProps)(
//   FileUploadButton
// );