import React, { Component } from 'react';
import PropTypes from 'prop-types';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
	DashboardMenuItem,
	MenuItemLink
} from 'react-admin';
import Divider from '@material-ui/core/Divider';
import UserIcon from '@material-ui/icons/People';
import ArtworkIcon from '@material-ui/icons/Image';
import ArtworkMedia from '@material-ui/icons/PhotoAlbum';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import ContractIcon from '@material-ui/icons/Description';
import TokenIcon from '@material-ui/icons/Layers';
import ArtworksBadgesIcon from '@material-ui/icons/FilterFrames'
import BadgesIcon from '@material-ui/icons/FilterNone'
import TippingIcon from '@material-ui/icons/MonetizationOn'
import TheatersIcon from '@material-ui/icons/Theaters';

class Menu extends Component {
	static propTypes = {
		onMenuClick: PropTypes.func,
		logout: PropTypes.object,
	};

	render() {
		const { onMenuClick } = this.props;
		return (
			<div className="sidebar-menu">
				<DashboardMenuItem style={{ fontSize: "1rem" }} onClick={onMenuClick} />
				<MenuItemLink
					to={`/artworks`}
					primaryText="Artworks"
					leftIcon={<ArtworkIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/artworks_media`}
					primaryText="Artworks Media"
					leftIcon={<ArtworkMedia />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/artworks_extras`}
					primaryText="Artworks Extras"
					leftIcon={<ArtTrackIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/video_ids`}
					primaryText="Artwork Video"
					leftIcon={<TheatersIcon />}
					onClick={onMenuClick}
				/>
				<Divider />
				<MenuItemLink
					to={`/tokens`}
					primaryText="Tokens"
					leftIcon={<TokenIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/contracts`}
					primaryText="Contracts"
					leftIcon={<ContractIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/transactions`}
					primaryText="Transactions"
					leftIcon={<TippingIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/users`}
					primaryText="Users"
					onClick={onMenuClick}
					leftIcon={<UserIcon />}
				/>
				<Divider />
				<MenuItemLink
					to={`/merchpunk_orders`}
					primaryText="MP Orders"
					leftIcon={<TippingIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/merchpunk_order_items`}
					primaryText="MP Order Items"
					leftIcon={<TippingIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/merchpunk_shipping_invoices`}
					primaryText="MP Shipping Invoices"
					leftIcon={<ContractIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/merchpunk_store_items`}
					primaryText="MP Store Items"
					leftIcon={<ArtworkIcon />}
					onClick={onMenuClick}
				/>
				<Divider />
				<MenuItemLink
					to={`/badges`}
					primaryText="Badges"
					leftIcon={<BadgesIcon />}
					onClick={onMenuClick}
				/>
				<MenuItemLink
					to={`/artworks_badges`}
					primaryText="Artwork Badges"
					leftIcon={<ArtworksBadgesIcon />}
					onClick={onMenuClick}
				/>
			</div >
		);
	}
}

const mapStateToProps = state => ({
	open: state.admin.ui.sidebarOpen,
	theme: state.theme
});

const enhance = compose(
	withRouter,
	connect(
		mapStateToProps,
		{}
	)
);

export default enhance(Menu);