import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { object, func } from "prop-types";
import {
  showNotification,
  Button,
  SaveButton,
  SimpleForm,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin';
import { transferToken } from "../lib/request";
import { withStyles } from '@material-ui/core/styles';
import { refreshView as refreshViewAction } from 'ra-core';
import IconContentAdd from '@material-ui/icons/Add';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

class TransferTokenButton extends Component {
  state = {
    showDialog: false
  };

  handleClick = () => {
    this.setState({ showDialog: true });
  };

  handleCloseClick = () => {
    this.setState({ showDialog: false });
  };

  handleMintToken = () => {
    document
      .getElementById('transfer-token')
      .dispatchEvent(new Event('submit', { cancelable: true }))
  };

  handleSubmit = async (values, form) => {
    const { showNotification, record, refreshView } = this.props;
    const { id: token_id } = record;
    const { new_owner_id } = values;

    const newTokenRequestData = {
      new_owner_id,
      token_id
    }

    const mintTokenSuccess = await transferToken(newTokenRequestData);
    if (!mintTokenSuccess) showNotification(`ERROR: Transfer token ${token_id} failed`, "warning");
    else {
      showNotification(`Transfer token ${token_id} to user ${new_owner_id} successfully`);
      refreshView();
      form.reset();
    }
    this.setState({ showDialog: false });
  };

  render() {
    const { showDialog } = this.state;
    const { isSubmitting, classes } = this.props;

    return (
      <Fragment>
        <Button onClick={this.handleClick} label="Transfer Token">
          <IconContentAdd />
        </Button>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={this.handleCloseClick}
          aria-label="Transfer Token"
        >
          <DialogTitle>Transfer Token</DialogTitle>
          <DialogContent>
            <SimpleForm
              // We override the react-final-form name to avoid collision with the react-admin main form
              form="transfer-token"
              // We override the react-final-form onSubmit prop to handle the submission ourselves
              onSubmit={this.handleSubmit}
              // We want no toolbar at all as we have our modal actions
              toolbar={null}
            >
              <ReferenceInput label="New Owner" source="new_owner_id" reference="users" allowEmpty filterToQuery={searchText => ({ username: searchText })} >
                <AutocompleteInput optionText={choice => `${choice.id}: ${choice.username}`} theme={{
                  suggestionsContainerOpen: classes.suggestionsContainerOpen
                }} {...this.props} />
              </ReferenceInput>
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <SaveButton
              label="Transfer Token"
              saving={isSubmitting}
              onClick={this.handleMintToken}
            />
            <Button label="Cancel" onClick={this.handleCloseClick}>
              <IconCancel />
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

TransferTokenButton.propTypes = {
  record: object,
  onSuccess: func
};
TransferTokenButton.defaultProps = {
  record: {},
  onSuccess: null
};

const mapStateToProps = state => ({
  // isSubmitting: isSubmitting('transfer-token')(state)
});

const mapDispatchToProps = {
  showNotification,
  refreshView: refreshViewAction
};

const styles = {
  suggestionsContainerOpen: {
    zIndex: 15000,
    top: "470px !important"
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(
  withStyles(styles)(TransferTokenButton)
);