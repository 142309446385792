import React, { useState, Fragment } from 'react';
import {
  Button,
  SaveButton,
  TextInput,
  NumberInput,
  useNotify,
  required
} from 'react-admin';
import { Form } from 'react-final-form';
import { saveAs } from 'file-saver';
import { retrieveArtworkFromTokenId, retrieveTransaction, generateShortenUrl } from "../lib/request";
import Jimp from 'jimp';
import JSZip from 'jszip';
import QRCode from 'qrcode';
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import BlockPunkLogo from "../static/blockpunk-logo.jpg";

const PrepareArtprintForShippingButton = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [message, setMessage] = useState(null);
  const notify = useNotify();

  const onShowDialog = () => {
    setShowDialog(true);
  };

  const onCloseDialog = () => {
    setShowDialog(false);
  };

  const sleep = (ms) => {
    return new Promise(resolve => {
      setTimeout(resolve, ms)
    })
  }

  const onPrepareNfc = async values => {
    const { fromTxId, toTxId, fromNfcId, nfcAnalyticUtm, qrcodeAnalyticUtm } = values;
    const zip = new JSZip();
    var imgFolder = zip.folder("qrcodes");

    let nfcId = fromNfcId;
    for (let txId = fromTxId; txId <= toTxId; txId++) {
      const transaction = await retrieveTransaction(txId);
      const { token_id: tokenId, metadata, status } = transaction || {};
      // transaction status is not PREORDED_PAYMENT_COMPLETED, ignore
      if (status !== "PREORDED_PAYMENT_COMPLETED") continue;

      const { shipping_details: shippingDetails } = metadata || {};
      // no shipping detail, ignore
      if (!shippingDetails) continue;

      const { address } = shippingDetails;
      const { countryCode } = address;
      const lang = countryCode.toLowerCase() === "jp" ? "jp" : "en";

      const artwork = await retrieveArtworkFromTokenId(tokenId);
      if (!artwork) continue;
      const { slug, type } = artwork || {};
      // not artprint, ignore
      if (!type || type.toLowerCase() !== "p") continue;

      const finalNfcUtm = nfcAnalyticUtm.replace("{slug}", slug);
      const finalQrUtm = qrcodeAnalyticUtm.replace("{slug}", slug);

      const originalUrl = `${process.env.REACT_APP_WEBSITE_DOMAIN}/${lang}/certificate/${tokenId}?${finalNfcUtm}`;
      const shortenPath = `print${nfcId}`;
      // console.log("originalUrl", originalUrl);
      // console.log("shortenPath", shortenPath);
      let shortenUrl = await generateShortenUrl(originalUrl, shortenPath);
      // console.log(shortenUrl);
      if (!shortenUrl) {
        notify("Failed to generate Shorten URL", "error");
        break;
      }

      const qrCodeUrl = `${shortenUrl}?${finalQrUtm}`;
      // generate QR code
      const qrDataUrl = await QRCode.toDataURL(qrCodeUrl, { margin: 2, width: 240, version: 8, error: "Q" });
      let qrbase64 = qrDataUrl.replace(/^data:image\/\w+;base64,/, "");
      let qrBuffer = Buffer.from(qrbase64, 'base64');

      // Blit QR code image to template image
      const baseImage = await new Jimp(250, 320, "#ffffff");
      const qrImage = await Jimp.read(qrBuffer);
      const blockpunkLogo = await Jimp.read(BlockPunkLogo);

      let tempImage = await baseImage.blit(qrImage, 5, 70);
      tempImage = await tempImage.blit(blockpunkLogo, 95, 160);

      // add nfc id label
      const font = await Jimp.loadFont(`https://staging-hq.blockpunk.net/overpass-bold.fnt`);
      tempImage = await tempImage.print(font, 20, 5, nfcId);
      let finalImageBase64 = await tempImage.getBase64Async(Jimp.AUTO);
      finalImageBase64 = finalImageBase64.replace(/^data:image\/\w+;base64,/, "");
      imgFolder.file(`${nfcId}.jpg`, finalImageBase64, { base64: true });

      setMessage(`Prepared transaction ${txId}`);
      nfcId += 1;
      await sleep(2000);
    }

    let blob = await zip.generateAsync({ type: "blob" })
    saveAs(blob, "qrcodes.zip");
    setMessage(null);
    notify('Prepare artprint transaction completed');
    setShowDialog(false);
  }

  return (
    <Fragment>
      <Button onClick={onShowDialog} label="Prepare Artprint for Shipping">
        <AirportShuttleIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={onCloseDialog}
      >
        <DialogTitle>Prepare Artprint for Shipping</DialogTitle>
        <Form
          onSubmit={onPrepareNfc}
          render={({ handleSubmit, submitting }) => {
            const saveButtonLabel = submitting ? (message || "Preparing...") : "Start Preparing";
            return (
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <NumberInput source="fromTxId" label="From Transaction Id" validate={required()} />
                  <NumberInput source="toTxId" label="To Transaction Id" validate={required()} />
                  <NumberInput source="fromNfcId" label="From Nfc Id" validate={required()} fullWidth />
                  <TextInput source="nfcAnalyticUtm" label="Nfc Analytic UTM" defaultValue="" fullWidth />
                  <TextInput source="qrcodeAnalyticUtm" label="Qr Code Analytic UTM" defaultValue="" fullWidth />
                </DialogContent>
                <DialogActions>
                  <SaveButton
                    label={saveButtonLabel}
                    saving={submitting}
                    handleSubmitWithRedirect={event => { handleSubmit(event) }} />
                  <Button label="Cancel" disabled={submitting} onClick={onCloseDialog}>
                    <IconCancel />
                  </Button>
                </DialogActions>
              </form>
            )
          }} />
      </Dialog>
    </Fragment>
  )
}

export default PrepareArtprintForShippingButton;