import React from 'react';
import {
  Button,
  useRefresh,
  useNotify
} from 'react-admin';
import { retrieveUser, sendMail } from "../lib/request";
import request from "superagent";
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

const RejectButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();

  const onReject = async () => {
    if (record.status === 'DISABLED') {
      notify('Artwork already rejected', 'error');
      return;
    }
    if (record.status === 'ACTIVE') {
      notify('Artwork already approved', 'error');
      return;
    }

    const { id, creator_id: creatorId } = record;
    const creator = await retrieveUser(creatorId);
    // console.log(creator);
    if (!creator) {
      notify('Retrieve creator failed', 'error');
      return;
    }

    const updatedRecord = { ...record, status: 'DISABLED' };
    const apiUrl = `${process.env.REACT_APP_API_ADMIN_ENDPOINT}/artworks`

    request.patch(`${apiUrl}?id=eq.${id}`)
      .set("Content-Type", "application/json")
      .set("apikey", process.env.REACT_APP_API_KEY)
      .send(updatedRecord)
      .end(async (err, res) => {
        if (err) {
          console.log(err);
          notify('Artwork not rejected', 'error');
          return;
        }

        const { email, username } = creator;
        const data = {
          "templateName": "en_artwork_not_approved",
          "sendTo": email,
          "data": {
            "username": `@${username}`
          }
        }
        const sendMailSuccess = await sendMail(data);

        if (!sendMailSuccess) {
          notify('Send notification email failed', 'error');
        } else {
          notify('Artwork rejected');
          refresh();
        }
      });
  }

  return (
    <Button onClick={onReject} label="Reject">
      <ThumbDownIcon />
    </Button>
  );
}

export default RejectButton;