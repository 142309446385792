import React from 'react';
import { addField } from 'ra-core';
import { useInput } from 'react-admin';
import { JsonEditor as Editor } from 'jsoneditor-react';
import 'jsoneditor-react/es/editor.min.css';

const JSONPrettyInput = (props) => {
  const { record, source } = props;
  const {
    input: { onChange }
  } = useInput(props);

  let jsonContent = {};
  if (record && record[source]) {
    jsonContent = record[source];
  }

  return (
    <Editor value={jsonContent} mode="text" onChange={onChange} />
  )
}

export default addField(JSONPrettyInput);