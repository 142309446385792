import React from 'react';
import { Link } from 'react-router-dom';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import { Button } from 'react-admin';

const AddArtworkBadgeButton = ({ record }) => (
  <Button
    component={Link}
    to={{
      pathname: '/artworks_badges/create',
      search: `?artwork_id=${record.id}`
    }}
    label="Link a Badge"
  >
    <ChatBubbleIcon />
  </Button>
);

export default AddArtworkBadgeButton;
