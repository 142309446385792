import React from 'react';

const getS3CDNImageURL = (url, size) => {
  if (!url) return null;
  if (url.includes("default/blockstudios-icon.png")) return url;

  let result = url;
  result = result.replace("blockstudios.net/", "blockpunk.net/");
  result = result.replace(".net/", `.net/${size}/`);
  result = result.replace("//img.", "//img-cdn.");
  return result;
};

const ImageThumbnailField = ({ record, source, className, title }) => {
  const imageUrl = record[source];
  const thumbUrl = getS3CDNImageURL(imageUrl, "800w");

  return (
    <div className={className}>
      <img className={className} src={thumbUrl} alt={title} />
    </div>
  )
};

ImageThumbnailField.defaultProps = {
  addLabel: true,
};

export default ImageThumbnailField;