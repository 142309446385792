import React from 'react';
import { TextField, ReferenceField, ArrayField, Datagrid } from 'react-admin';
import { Show, SimpleShowLayout, EditButton } from 'react-admin';
import { TopToolbar } from 'react-admin';
import ApproveButton from "./ApproveButton";
import RejectButton from "./RejectButton";
import RequestMoreInfoButton from "./RequestMoreInfoButton";
import ImageThumbnailField from '../components/ImageThumbnailField';
import MintTokenButton from './MintTokenButton';

const ArtworkShowActions = ({ basePath, data, resource }) => (
  <TopToolbar>
    <ApproveButton record={data} />
    <RequestMoreInfoButton record={data} />
    <RejectButton record={data} />
    <MintTokenButton record={data} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);
const ArtworkShow = (props) => (
  <Show actions={<ArtworkShowActions />} {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="slug" />
      <ImageThumbnailField className="artwork" source="image_url" title="Artwork" />
      <ReferenceField label="Creator" source="creator_id" reference="users" link="show" allowEmpty>
        <TextField source="username" />
      </ReferenceField>
      <TextField source="type" />
      <TextField source="status" />
      <TextField source="sale_status" />
      <ArrayField source="attr_data">
        <Datagrid>
          <TextField source="key" />
          <TextField source="lang" />
          <TextField source="value" component="pre" />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
);

export default ArtworkShow;