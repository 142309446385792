import React from 'react';
import { List, Datagrid, TextField, DateField } from 'react-admin';
import { ShowButton, EditButton, TextInput, BooleanInput, DateTimeInput } from 'react-admin';
import { Filter, SimpleShowLayout, required, RadioButtonGroupInput } from 'react-admin';
import { TabbedForm, FormTab, ArrayInput, SimpleFormIterator } from 'react-admin';
import { Create, Edit, Show } from 'react-admin';
import withStyles from '@material-ui/core/styles/withStyles';
import CurrentDateTimeInput from '../components/CurrentDateTimeInput'
import ImageUrlTextInput from '../components/ImageUrlTextInput'
import JSONPrettyField from '../components/JSONPrettyField';
import ImageThumbnailField from '../components/ImageThumbnailField';

const UserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Username" source="username" alwaysOn />
    <TextInput label="Email" source="email" alwaysOn />
  </Filter>
);
const UserList = (props) => (
  <List {...props} filters={<UserFilter />} sort={{ field: 'id', order: 'DESC' }}>
    <Datagrid>
      <TextField source="id" />
      <ImageThumbnailField className="avatar" source="profile_image" title="avatar" />
      <TextField source="email" />
      <TextField source="username" />
      <TextField source="eth_address" />
      <ShowButton />
      <EditButton />
    </Datagrid>
  </List>
);
const UserShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ImageThumbnailField className="avatar" source="profile_image" title="avatar" />
      <TextField source="email" />
      <TextField source="username" />
      <TextField source="lang" title="Preferred Lang" />
      <TextField source="paypal_email" />
      <TextField source="first_name" />
      <TextField source="last_name" />
      <TextField source="metamask_id" />
      <TextField source="eth_address" />
      <JSONPrettyField source="display_name" />
      <JSONPrettyField source="bio" />
      <DateField source="inserted_at" showTime />
      <DateField source="updated_at" showTime />
    </SimpleShowLayout>
  </Show>
);

const inputStyles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  fullWidth: {
    '& div:first-child': { width: '100%' }
  },
};

const UserEdit = withStyles(inputStyles)(({ classes, ...props }) => (
  <Edit title="Edit User" {...props}>
    <TabbedForm submitOnEnter={false}>
      <FormTab label="identity">
        <TextInput source="id" disabled />
        <TextInput source="email" type="email" validate={required()} formClassName={classes.inlineBlock} />
        <TextInput source="username" validate={required()} formClassName={classes.inlineBlock} />
        <RadioButtonGroupInput source="lang" label="Preferred Lang" choices={[
          { id: 'en', name: 'English' },
          { id: 'jp', name: 'Japanese' },
        ]} />
        <TextInput source="paypal_email" />
        <TextInput source="first_name" formClassName={classes.inlineBlock} />
        <TextInput source="last_name" formClassName={classes.inlineBlock} />
        <BooleanInput label="Is Creator" source="is_creator" />
      </FormTab>
      <FormTab label="blockchain">
        <TextInput source="metamask_id" />
        <TextInput source="eth_address" />
      </FormTab>
      <FormTab label="Display">
        <ImageUrlTextInput source="profile_image" />
        <ImageUrlTextInput source="profile_cover" />
        <ArrayInput source="display_name" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="lang" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="bio" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="lang" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="social_profiles" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="platform" />
            <TextInput source="url" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="misc">
        <TextInput source="inserted_at" disabled />
        <CurrentDateTimeInput source="updated_at" />
      </FormTab>
    </TabbedForm>
  </Edit>
));

const UserCreate = withStyles(inputStyles)(({ classes, ...props }) => (
  <Create title="Create User" {...props}>
    <TabbedForm submitOnEnter={false}>
      <FormTab label="identity">
        <TextInput source="email" type="email" validate={required()} formClassName={classes.inlineBlock} />
        <TextInput source="username" validate={required()} formClassName={classes.inlineBlock} />
        <RadioButtonGroupInput source="lang" label="Preferred Lang" validate={required()} defaultValue="en" choices={[
          { id: 'en', name: 'English' },
          { id: 'jp', name: 'Japanese' },
        ]} />
        <TextInput source="paypal_email" />
        <TextInput source="first_name" formClassName={classes.inlineBlock} />
        <TextInput source="last_name" formClassName={classes.inlineBlock} />
        <BooleanInput label="Is Creator" source="is_creator" />
      </FormTab>
      <FormTab label="blockchain">
        <TextInput source="metamask_id" />
        <TextInput source="eth_address" />
      </FormTab>
      <FormTab label="Display">
        <ImageUrlTextInput source="profile_image" />
        <ImageUrlTextInput source="profile_cover" />
        <ArrayInput source="display_name" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="lang" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="bio" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="lang" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="social_profiles" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="platform" />
            <TextInput source="url" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="misc">
        <DateTimeInput source="inserted_at" defaultValue={new Date()} />
        <DateTimeInput source="updated_at" defaultValue={new Date()} />
      </FormTab>
    </TabbedForm>
  </Create>
));

export default {
  list: UserList,
  create: UserCreate,
  edit: UserEdit,
  show: UserShow
};