import React from 'react';
import underContruction from "./static/underconstruction.png"

export default ({ permissions }) => {
	// console.log(permissions);
	return (
		<div style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center" }}>
			<img style={{ objectFit: "contain" }} src={underContruction} alt="" />
		</div>
	)
};