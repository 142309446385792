import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';
import { NumberInput, EditButton, TextInput, DateTimeInput } from 'react-admin';
import { Show, SimpleShowLayout, RadioButtonGroupInput, ArrayInput, SimpleFormIterator } from 'react-admin';
import { Create, Edit, TabbedForm, FormTab } from 'react-admin';
import CurrentDateTimeInput from '../components/CurrentDateTimeInput'
import withStyles from '@material-ui/core/styles/withStyles';
import ImageThumbnailField from '../components/ImageThumbnailField';

import ImageUrlTextInput from '../components/ImageUrlTextInput'

const BadgesList = (props) => (
  <List {...props}>
    <Datagrid>
      <TextField source="id" />
      <ImageThumbnailField className="avatar" source="image_url" title="image" />
      <TextField source="key" />
      <TextField source="type" />
      <TextField source="status" />
      <EditButton />
    </Datagrid>
  </List>
);
const BadgesShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ImageThumbnailField source="image_url" title="image" />
      <TextField source="key" />
      <TextField source="type" />
      <TextField source="status" />
    </SimpleShowLayout>
  </Show>
);

const inputStyles = {
  inlineBlock: { display: 'inline-flex', marginRight: '1rem' },
  fullWidth: {
    '& div:first-child': { width: '100%' }
  },
};

const BadgesEdit = withStyles(inputStyles)(({ classes, ...props }) => (
  <Edit {...props}>
    <TabbedForm submitOnEnter={false}>
      <FormTab label="identity">
        <TextInput source="id" disabled />
        <ImageUrlTextInput source="image_url" />
        <TextInput source="key" />
        <RadioButtonGroupInput source="type" choices={[
          { id: 'USER', name: 'User' },
          { id: 'ARTWORK', name: 'Artwork' },
        ]} />
        <RadioButtonGroupInput source="status" choices={[
          { id: 'ACTIVE', name: 'Active' },
          { id: 'DISABLED', name: 'Disabled' },
        ]} />
      </FormTab>
      <FormTab label="info">
        <ArrayInput source="title" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="lang" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="description" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="lang" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="misc">
        <TextInput source="inserted_at" disabled />
        <CurrentDateTimeInput source="updated_at" />
      </FormTab>
    </TabbedForm>
  </Edit>
));

const BadgesCreate = withStyles(inputStyles)(({ classes, ...props }) => (
  <Create title="Create a Badge" {...props}>
    <TabbedForm submitOnEnter={false}>
      <FormTab label="identity">
        <NumberInput source="id" />
        <ImageUrlTextInput source="image_url" />
        <TextInput source="key" />
        <RadioButtonGroupInput source="type" choices={[
          { id: 'USER', name: 'User' },
          { id: 'ARTWORK', name: 'Artwork' },
        ]} />
        <RadioButtonGroupInput source="status" choices={[
          { id: 'ACTIVE', name: 'Active' },
          { id: 'DISABLED', name: 'Disabled' },
        ]} />
      </FormTab>
      <FormTab label="info">
        <ArrayInput source="title" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="lang" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="description" formClassName={classes.fullWidth}>
          <SimpleFormIterator>
            <TextInput source="lang" />
            <TextInput source="value" />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
      <FormTab label="misc">
        <DateTimeInput source="inserted_at" defaultValue={new Date()} />
        <DateTimeInput source="updated_at" defaultValue={new Date()} />
      </FormTab>
    </TabbedForm>
  </Create>
));

export default {
  list: BadgesList,
  create: BadgesCreate,
  edit: BadgesEdit,
  show: BadgesShow
};